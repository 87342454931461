import React, { useMemo } from "react";
import { ColDef, GetRowIdParams, GridOptions, ValueFormatterParams } from "@ag-grid-community/core";
import { SelectAll } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetMultiProjectQuery, useGetMultiProjectStatisticsQuery } from "fond/api";
import * as turf from "fond/turf";
import { SystemsOfMeasurement } from "fond/types";
import { sqMetresToSqKilometers, sqMetresToSqMiles } from "fond/utils/area";
import { useAppSelector } from "fond/utils/hooks";
import { formatFractionPercent, formatInteger, formatNumber } from "fond/utils/number";
import { AgGrid } from "fond/widgets";

import { convertDensity, convertPath } from "../helper";

interface RowData {
  id:
    | "addresses"
    | "area"
    | "attFiberCoverage"
    | "density"
    | "medianIncome"
    | "name"
    | "paths"
    | "score"
    | "tMobileFiberCoverage"
    | "underserved"
    | "verizonFiberCoverage";
  property: string;
  value: string | number;
}

const Properties: React.FC = () => {
  const { selectedFeature } = useAppSelector((state) => state.project);
  const multiProjectId = useAppSelector((state) => state.project.projectId);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId);
  const { data } = useGetMultiProjectStatisticsQuery(multiProjectId ?? skipToken);
  const selectedFeatureData = useMemo(
    () => selectedFeature && data?.Areas.find(({ Area: { ID } }) => ID === selectedFeature.layerId),
    [data?.Areas, selectedFeature]
  );
  const selectedAreaData = useMemo(
    () => multiProject?.Areas.find(({ ID }) => ID === selectedFeature?.layerId),
    [multiProject?.Areas, selectedFeature?.layerId]
  );

  const valueFormatter = useMemo(
    () => (params: ValueFormatterParams<RowData>) => {
      if (!params.data) return null;

      if (params.value === undefined) return "Loading...";
      const { id } = params.data;
      if (id === "name") return params.value;
      if (["addresses", "paths"].includes(id)) return formatInteger(params.value);
      if (["area", "density"].includes(id)) return formatNumber(params.value, 2);
      if (["underserved", "sdu", "attFiberCoverage", "tMobileFiberCoverage", "verizonFiberCoverage"].includes(id))
        return formatFractionPercent(params.value);

      if (id === "medianIncome") return `$${formatNumber(params.value, 2)}`;

      return formatNumber(params.value, 0);
    },
    []
  );

  const columns: ColDef[] = useMemo(
    () => [
      {
        headerName: "Property",
        field: "property",
        flex: 1,
        resizable: false,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "",
        field: "value",
        flex: 1,
        resizable: false,
        suppressHeaderMenuButton: true,
        valueFormatter,
      },
    ],
    [valueFormatter]
  );

  // These values need to update if the underlying areas change (e.g. playing around with scores)
  const rowData = useMemo(() => {
    if (!selectedFeature || !selectedFeatureData) return [];

    return [
      {
        id: "name",
        property: "Name",
        value: selectedAreaData?.Name,
      },
      {
        id: "score",
        property: "Score",
        value: selectedAreaData?.Score,
      },
      {
        id: "area",
        property: `Area (${multiProject?.SystemOfMeasurement === SystemsOfMeasurement.Imperial ? "sq mi" : "sq km"})`,
        value:
          multiProject?.SystemOfMeasurement === "imperial"
            ? sqMetresToSqMiles(turf.area(selectedFeature.feature))
            : sqMetresToSqKilometers(turf.area(selectedFeature.feature)),
      },
      {
        id: "paths",
        property: `Paths (${multiProject?.SystemOfMeasurement === SystemsOfMeasurement.Imperial ? "ft" : "m"})`,
        value: convertPath(selectedFeatureData.StreetCounts.StreetIntersectionLengthMetres, multiProject?.SystemOfMeasurement),
      },
      {
        id: "addresses",
        property: "Addresses",
        value: selectedFeatureData.AddressCounts.AddressCount,
      },
      {
        id: "density",
        property: `Density (${multiProject?.SystemOfMeasurement === SystemsOfMeasurement.Imperial ? "sq mi" : "sq km"})`,
        value: convertDensity(selectedFeatureData.AddressDensity.AddressesPerSquareMeters, multiProject?.SystemOfMeasurement),
      },
      {
        id: "sdu",
        property: "SDU proportion",
        value: selectedFeatureData.AddressBreakdown.Sdu.Proportion,
      },
      {
        id: "underserved",
        property: "Underserved proportion",
        value: selectedFeatureData.AddressUnderservedProportion.AddressUnderservedProportion,
      },
      {
        id: "medianIncome",
        property: "Median Income",
        value: selectedFeatureData.WeightedMedianIncome.WeightedMedianIncomeDollars,
      },
      {
        id: "attFiberCoverage",
        property: "AT&T fiber coverage",
        value: selectedFeatureData.ProviderFiberCoverage["AT&T"].FiberCoverageProportion,
      },
      {
        id: "tMobileFiberCoverage",
        property: "T-Mobile fiber coverage",
        value: selectedFeatureData.ProviderFiberCoverage["T-Mobile"].FiberCoverageProportion,
      },
      {
        id: "verizonFiberCoverage",
        property: "Verizon fiber coverage",
        value: selectedFeatureData.ProviderFiberCoverage.Verizon.FiberCoverageProportion,
      },
    ];
  }, [selectedFeature, selectedFeatureData, multiProject?.SystemOfMeasurement, selectedAreaData?.Name, selectedAreaData?.Score]);

  if (!selectedFeature) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <SelectAll color="action" sx={{ mb: 2 }} />
          <Typography variant="body3">Select a feature to view</Typography>
        </Box>
      </Box>
    );
  }

  const gridOptions: GridOptions = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    suppressMovableColumns: true,
    suppressRowClickSelection: true,
    rowBuffer: 20,
    domLayout: "normal",
    getRowId: (params: GetRowIdParams) => String(params.data.id),
  };

  return <AgGrid columnDefs={columns} rowData={rowData} gridOptions={gridOptions} size="compact" variant="borderless" />;
};

export default Properties;
