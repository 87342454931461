import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { Feature, MultiPolygon } from "geojson";
import { get, groupBy } from "lodash";

import { fondServiceURL, useGetMultiProjectQuery } from "fond/api";
import SourceAndLayers from "fond/map/SourceAndLayers";
import VectorTileLayer from "fond/map/VectorTileLayer";
import { MultiReport, Report, Store } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { toSystemOfMeasurement } from "fond/utils";
import { selectAllStylesByLayers } from "fond/utils/configurations";

interface IProps {
  multiReport: MultiReport;
  reports: Report[];
  layerConfigs: Array<LayerConfig | SublayerConfig>;
  layerView: {
    [key: string]: boolean;
  };
  styles?: LayerStyle[];
}

const ReportMapContent: React.FC<IProps> = ({ multiReport, reports, layerConfigs, layerView, styles }: IProps) => {
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: multiProject } = useGetMultiProjectQuery(versionId ?? skipToken);

  const features = useMemo(() => {
    const polygons: Feature<MultiPolygon>[] = [];

    // Add the sub area boundaries and inject report attributes
    multiProject?.Areas.forEach((area) => {
      const report = reports?.find((r: Report) => r.MultiProjectArea?.ID === area.ID);
      polygons.push({
        type: "Feature",
        properties: {
          id: area.ID,
          boundaryId: area.ID,
          name: area.Name,
          npv: report?.Npv,
          irr: (report?.Irr ?? 0) * 100,
          roi: (report?.Roi ?? 0) * 100,
          netRevenue: report?.NetRevenue,
          netCost: report?.NetCost,
          // The conversion is reversed because the length is the denominator.
          costPerMeter: toSystemOfMeasurement(report?.CostPerMeter, { from: multiProject.SystemOfMeasurement, to: "metric" }),
          costperPassing: report?.CostPerPassing,
          totalPassing: report?.TotalPassings,
          totalConnections: report?.TotalConnections,
          totalFootprintMeters: toSystemOfMeasurement(report?.TotalFootprintMeters, { from: "metric", to: multiProject.SystemOfMeasurement }),
        },
        geometry: area.Boundary,
      });
    });

    return polygons;
  }, [multiProject, reports]);

  // The configuration data needs to be jumbled up together for e.g. the legend; however SourceAndLayers/mapbox doesn't
  // like us providing the same layer configuration to multiple sources, so separate those configs here.
  const data = useMemo(() => {
    const configs = groupBy(
      layerConfigs.filter(({ Exclude }) => !Exclude),
      "Key"
    );

    const subareaLayerConfigs = get(configs, "subareas", []);
    const multiReportConfigs = reports?.flatMap(({ ID }) => get(configs, `output/service_area_${ID}`, [])) ?? [];

    return {
      subareaLayerConfigs,
      subareaStyles: selectAllStylesByLayers(subareaLayerConfigs, styles),
      multiReportConfigs,
      multiReportStyles: selectAllStylesByLayers(multiReportConfigs, styles),
    };
  }, [layerConfigs, reports, styles]);

  const checkVisible = (id: string, layerView: { [key: string]: boolean }) => {
    return layerView[id];
  };

  const multiReportSource = useMemo(
    () => ({
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features,
      },
      promoteId: "id",
    }),
    [features]
  );

  return (
    <>
      <SourceAndLayers
        sourceId="multiReportBoundary-source"
        source={multiReportSource}
        styles={data.subareaStyles}
        layers={data.subareaLayerConfigs}
        layerVisibilities={layerView}
        isVisible={checkVisible}
      />

      {multiReport && (
        <VectorTileLayer
          sourceKey="multiReport-source"
          tileURL={`${fondServiceURL}/v2/multi-reports/${multiReport.ID}/service-area-tiles/{z}/{x}/{y}`}
          layerConfigs={data.multiReportConfigs}
          styles={data.multiReportStyles}
          layerVisibilities={layerView}
          isVisible={checkVisible}
          beforeId={data.subareaStyles.at(-1)?.ID}
        />
      )}
    </>
  );
};

export default ReportMapContent;
