import React from "react";
import { Box, Typography } from "@mui/material";

import { Swab } from "ui";

import { palette, unFocusColor } from "fond/cityPlanner/configuration/cityReportConfiguration";

const NpvLegend: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Swab color={palette.Npv[0]} strokeColor={palette.Npv[0]} opacity={0.3} />
        <Typography ml={1}>NPV &lt; 0</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Swab color={palette.Npv[1]} strokeColor={palette.Npv[1]} opacity={0.3} />
        <Typography ml={1}>NPV &gt; 0</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Swab color={unFocusColor} strokeColor="#999" opacity={0.3} />
        <Typography ml={1}>No build zone</Typography>
      </Box>
    </Box>
  );
};

export default NpvLegend;
