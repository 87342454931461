import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { selectAllVersionLayerConfigsInOrder, selectAllVersionStyles, useGetVersionConfigQuery } from "fond/api";
import { SubareaLayerId } from "fond/cityPlanner/configuration";
import { Map } from "fond/cityPlanner/Map";
import MapContent from "fond/cityPlanner/Map/MapContent";
import BaseMapButtons from "fond/map/BaseMapButtons";
import { MapContext } from "fond/map/MapProvider";
import { Store } from "fond/types";
import { isValidBoundingBox } from "fond/utils";

interface IProps {
  cityId: string;
}

const CityPlannerFolderMap: React.FC<IProps> = ({ cityId }) => {
  const { map } = useContext(MapContext);
  const layerConfigs = useSelector((state: Store) => selectAllVersionLayerConfigsInOrder(state, cityId));
  const styles = useSelector((state: Store) => selectAllVersionStyles(state, cityId));
  const { project } = useSelector((state: Store) => state.project.projects[cityId] || {});
  useGetVersionConfigQuery(cityId);

  const layerView = useMemo(
    () => ({
      [SubareaLayerId.ROOT]: true,
      [SubareaLayerId.BY_SCORE]: true,
      [SubareaLayerId.BY_NAME]: false,
      [SubareaLayerId.BOUNDARY]: false,
    }),
    []
  );

  const onClickZoomToDesignHandler = () => {
    if (project.BoundingBox && isValidBoundingBox(project.BoundingBox)) {
      map?.fitBounds(project.BoundingBox);
    }
  };

  const onClickZoomInHandler = () => {
    map?.zoomIn();
  };

  const onClickZoomOutHandler = () => {
    map?.zoomOut();
  };

  return layerConfigs && styles ? (
    <Map mapContent={<MapContent layerConfigs={layerConfigs} layerView={layerView} styles={styles} />} showFooter={false} persistViewChange={false}>
      <Box className="right-sidebar-section">
        <div />
        <BaseMapButtons
          editMode="none"
          onClickZoomToDesign={onClickZoomToDesignHandler}
          onClickZoomIn={onClickZoomInHandler}
          onClickZoomOut={onClickZoomOutHandler}
          currentLocationButton={false}
        />
      </Box>
    </Map>
  ) : null;
};

export default CityPlannerFolderMap;
