import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { useGetVersionConfigQuery } from "fond/api";
import { CityReportLayerId, generateCityReportMapConfiguration } from "fond/cityPlanner/configuration/cityReportConfiguration";
import { Map } from "fond/cityPlanner/Map";
import ReportMapContent from "fond/cityPlanner/Map/ReportMapContent";
import BaseMapButtons from "fond/map/BaseMapButtons";
import { MapContext } from "fond/map/MapProvider";
import { getCurrentProject } from "fond/project";
import { FullMultiReport, MultiProject, MultiReportCategory, Report, Store } from "fond/types";
import { isValidBoundingBox } from "fond/utils";
import { selectLayersFromConfig, selectStylesFromConfig } from "fond/utils/configurations";
import { MapContainer } from "fond/views/Report/report.styles";
import { MapCalloutContainer, MapLegendBox } from "fond/views/Report/ReportMapLegend/ReportMapLegend.styles";

import CityReportMapControl from "./CityReportMapControl";

interface IProps {
  multiProject: MultiProject;
  multiReport?: FullMultiReport | null;
  reports?: Report[] | null;
  multiReportCategory: MultiReportCategory;
  setMultiReportCategory: (method: MultiReportCategory) => void;
  sliderRange: number[] | null;
  setSliderRange(sliderRange: number[]): void;
  filterRange: number[] | null;
}

const CityReportMap: React.FC<IProps> = ({
  multiProject,
  multiReport,
  reports,
  multiReportCategory,
  setMultiReportCategory,
  sliderRange,
  setSliderRange,
  filterRange,
}) => {
  const { map } = useContext(MapContext);
  const project = useSelector((state: Store) => getCurrentProject(state.project));

  useGetVersionConfigQuery(multiProject.ID);

  const [layerVisibilities, setLayerVisibilities] = useState<Record<string, boolean>>({});

  const cityMapConfiguration = useMemo(
    () => (reports ? generateCityReportMapConfiguration(reports, multiReportCategory) : null),
    [reports, multiReportCategory]
  );

  const layerConfigs = useMemo(() => (cityMapConfiguration ? selectLayersFromConfig(cityMapConfiguration, ["LAYER"]) : []), [cityMapConfiguration]);

  const styles = useMemo(() => (cityMapConfiguration ? selectStylesFromConfig(cityMapConfiguration) : null), [cityMapConfiguration]);

  useEffect(() => {
    reports?.forEach((report) => {
      // When a subarea gains or loses focus we set the visibility of the cabinet level style that applies the colors to the map.
      const cabinetFeatureStyleId = `cityReportArea${multiReportCategory}Layer-${report.ID}-polygon-fill`;
      if (
        (!sliderRange || (report[multiReportCategory] && sliderRange.includes(report[multiReportCategory]))) &&
        (!filterRange || (report[multiReportCategory] && filterRange.includes(report[multiReportCategory])))
      ) {
        map?.setLayoutProperty(cabinetFeatureStyleId, "visibility", "visible");
        map?.setFeatureState({ source: "multiReportBoundary-source", id: report?.MultiProjectArea?.ID }, { hasFocus: true });
      } else {
        map?.setLayoutProperty(cabinetFeatureStyleId, "visibility", "none");
        map?.setFeatureState({ source: "multiReportBoundary-source", id: report?.MultiProjectArea?.ID }, { hasFocus: false });
      }
    });
  }, [map, sliderRange, multiReportCategory, reports, filterRange]);

  useEffect(() => {
    setLayerVisibilities(
      Object.fromEntries(
        layerConfigs.map((layerConfig) => {
          return [layerConfig.ID, layerConfig.ID.startsWith(CityReportLayerId[multiReportCategory]) || layerConfig.Key === "subareas"];
        })
      )
    );
  }, [multiReport, layerConfigs, multiReportCategory]);

  const onClickZoomToDesignHandler = () => {
    if (project.BoundingBox && isValidBoundingBox(project.BoundingBox)) {
      map?.fitBounds(project.BoundingBox);
    }
  };

  const onClickZoomInHandler = () => {
    map?.zoomIn();
  };

  const onClickZoomOutHandler = () => {
    map?.zoomOut();
  };

  return layerConfigs && styles ? (
    <MapContainer height="100%">
      {reports && multiReport && (
        <>
          <Map
            mapContent={
              <ReportMapContent
                multiReport={multiReport}
                reports={reports}
                layerConfigs={layerConfigs}
                layerView={layerVisibilities}
                styles={styles}
              />
            }
            showFooter={false}
            persistViewChange={false}
          >
            <Box className="right-sidebar-section">
              <div />
              <BaseMapButtons
                editMode="none"
                onClickZoomToDesign={onClickZoomToDesignHandler}
                onClickZoomIn={onClickZoomInHandler}
                onClickZoomOut={onClickZoomOutHandler}
                currentLocationButton={false}
              />
            </Box>
          </Map>
          <MapLegendBox>
            <MapCalloutContainer mb={0.5}>
              <Box py={1.5} px={1}>
                <CityReportMapControl
                  multiReport={multiReport}
                  reports={reports}
                  category={multiReportCategory}
                  onCategoryChange={setMultiReportCategory}
                  systemOfMeaurement={multiReport?.SystemOfMeasurement}
                  onSliderChange={setSliderRange}
                />
              </Box>
            </MapCalloutContainer>
          </MapLegendBox>
        </>
      )}
    </MapContainer>
  ) : null;
};

export default CityReportMap;
