import React, { useCallback, useContext, useEffect, useState } from "react";
import { DrawModeChangeEvent } from "@mapbox/mapbox-gl-draw";
import { Box, ToggleButtonGroup } from "@mui/material";

import { MapContext } from "../MapProvider";

import AutoCarveButton from "./AutoCarveButton";
import CombineButton from "./CombineButton";
import CutButton from "./CutButton";
import DeleteButton from "./DeleteButton";
import ImportGeojsonButton from "./ImportGeojsonButton";
import PointButton from "./PointButton";
import PolygonButton from "./PolygonButton";
import RedoButton from "./RedoButton";
import SelectButton from "./SelectButton";
import SnappingButton from "./SnappingButton";
import SplitButton from "./SplitButton";
import StaticButton from "./StaticButton";
import { ToolbarAction } from "./tools";
import UncombineButton from "./UncombineButton";
import UndoButton from "./UndoButton";
import UnionButton from "./UnionButton";

import { Paper } from "./toolbar.styles";

const componentMap: Record<ToolbarAction, React.FC<IButtonProps>> = {
  [ToolbarAction.SIMPLE_SELECT]: SelectButton,
  [ToolbarAction.DRAW_POLYGON]: PolygonButton,
  [ToolbarAction.DRAW_POINT]: PointButton,
  [ToolbarAction.AUTO_CARVE]: AutoCarveButton,
  [ToolbarAction.COMBINE]: CombineButton,
  [ToolbarAction.CUT]: CutButton,
  [ToolbarAction.DELETE]: DeleteButton,
  [ToolbarAction.REDO]: RedoButton,
  [ToolbarAction.SNAP]: SnappingButton,
  [ToolbarAction.SPLIT]: SplitButton,
  [ToolbarAction.STATIC]: StaticButton,
  [ToolbarAction.UNCOMBINE]: UncombineButton,
  [ToolbarAction.UNDO]: UndoButton,
  [ToolbarAction.UNION]: UnionButton,
  [ToolbarAction.IMPORT_GEOJSON]: ImportGeojsonButton,
};

export interface IButtonProps {
  // The current draw mode
  mode: string | null;
}

const Toolbar: React.FC = () => {
  const { map, drawControl, isDrawing, toolbar } = useContext(MapContext);
  const [mode, setMode] = useState<string | null>(null);

  useEffect(() => {
    if (isDrawing && map?.hasControl(drawControl.current)) {
      setMode(drawControl.current.getMode());
    }
  }, [isDrawing, drawControl, map]);

  /**
   * Handles the changing of the current drawmode
   */
  const handleModeChange = useCallback(
    (event: DrawModeChangeEvent) => {
      setMode(event.mode);
    },
    [setMode]
  );

  useEffect(() => {
    map?.on("draw.modechange", handleModeChange);
    return () => {
      map?.off("draw.modechange", handleModeChange);
    };
  }, [map, handleModeChange]);

  if (!isDrawing) return null;

  const buttonFactory = (action: ToolbarAction) => {
    const Button = componentMap[action];
    return <Button key={action} mode={mode} />;
  };

  const renderButtonGroup = (actions: ToolbarAction[]) => {
    return (
      <Paper elevation={3} key={String(actions)}>
        <ToggleButtonGroup exclusive orientation="vertical" size="small" value={mode}>
          {actions.map(buttonFactory)}
        </ToggleButtonGroup>
      </Paper>
    );
  };

  const renderActionGroup = (actions: ToolbarAction[]) => {
    return (
      <Paper elevation={3} key={String(actions)}>
        {actions.map(buttonFactory)}
      </Paper>
    );
  };

  return (
    <Box sx={{ position: "absolute", top: 5, left: 5, display: "flex", flexDirection: "column", zIndex: 999 }}>
      {toolbar.current?.map(({ type, actions }) => {
        if (type === "mode") return renderButtonGroup(actions);
        return renderActionGroup(actions);
      })}
    </Box>
  );
};

export default Toolbar;
