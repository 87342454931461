import { AccordionDetailsProps } from "@mui/material";

import { StyledAccordionDetails } from "./AccordionDetails.styles";

/**
 * The content of an accordion. This is the area the user will see when the accordion is expanded.
 *
 * @see [MUI AccordionDetails API](https://mui.com/material-ui/api/accordion-details/)
 * @example
 * <AccordionDetails>
 *   The content goes here
 * </AccordionDetails>
 */
export const AccordionDetails: React.FC<AccordionDetailsProps> = ({ children, ...props }: AccordionDetailsProps) => {
  return <StyledAccordionDetails {...props}>{children}</StyledAccordionDetails>;
};
