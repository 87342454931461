import React, { useEffect, useState } from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { FormControl, FormHelperText, Slider, SliderProps as MuiSliderProps } from "@mui/material";

export type SliderProps = Partial<MuiSliderProps> & {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  validate?: any;
};

const SliderField: React.FC<SliderProps> = (props: SliderProps) => {
  const { color, defaultValue, fieldProps, name, validate, ...rest } = props;

  return (
    <Field
      name={name}
      render={({ input, meta }) => <SliderWrapper input={input} meta={meta} name={name} color={color} {...rest} />}
      validate={validate}
      defaultValue={defaultValue}
      {...fieldProps}
    />
  );
};

type SliderWrapperProps = Partial<Omit<MuiSliderProps, "onChange">> & FieldRenderProps<number, HTMLElement>;

/**
 * Sliders always expect a number or array of numbers, so we attempt to cast the value(s) to a number or array of numbers.
 * If the value is not castable to a number or array of numbers, we return 0.
 *
 * @param value the value to be cast to a number or array of numbers
 * @returns
 */
const forceNumericValue = (value: unknown): number | number[] => {
  if (typeof value === "number") {
    return value;
  }
  if (typeof value === "string") {
    return Number(value);
  }
  if (Array.isArray(value)) {
    return value.map(Number);
  }
  // for anything non-castable or non-numeric, return 0
  return 0;
};

const SliderWrapper: React.FC<SliderWrapperProps> = ({
  color,
  input: { name, onChange: onChangeCommitted, value, ...restInput },
  disabled,
  helperText,
  fullWidth,
  min,
  max,
  marks,
  onChange,
  size,
  step,
  sx,
  track,
  valueLabelDisplay,
  valueLabelFormat,
}: SliderWrapperProps) => {
  const [displayValue, setDisplayValue] = useState<number | number[]>(forceNumericValue(value));

  useEffect(() => {
    if (value !== displayValue) {
      setDisplayValue(forceNumericValue(value));
    }
  }, [value]);

  const handleOnChangeCommitted = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    onChangeCommitted(newValue);
  };

  const handleOnChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    setDisplayValue(newValue);
    onChange?.(event, newValue);
  };

  return (
    <FormControl data-testid={`${name}-slider-field`} fullWidth={fullWidth}>
      <Slider
        value={displayValue}
        name={name}
        onChange={handleOnChange}
        // Note that we use onChangeCommitted rather than onChange to avoid rapid form updates
        onChangeCommitted={handleOnChangeCommitted}
        disabled={disabled}
        color={color}
        size={size}
        min={min}
        max={max}
        step={step}
        sx={sx}
        marks={marks}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
        track={track}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SliderField;
