import { styled, Table, tableCellClasses } from "@mui/material";

import { TableProps } from "./Table";

export const BaseTable = styled(Table, {
  shouldForwardProp: (prop) => prop !== "variant",
})<{ variant: TableProps["variant"] }>(({ variant, theme }) => {
  if (variant !== "ag-grid-compact") return {};

  return {
    [`& .${tableCellClasses.root}`]: {
      fontSize: 11,
      padding: "6px 12px 6px 12px",
      height: "unset",
    },
    [`& .${tableCellClasses.root}.${tableCellClasses.paddingNone}`]: {
      padding: "0px",
    },
    [`& .${tableCellClasses.head}`]: {
      backgroundColor: "#f8f8f8",
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: theme.palette.biarri.secondary.paleGrey,
    },
    "& tr:last-child td": {
      borderBottom: "none",
    },
  };
});
