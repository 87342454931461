import * as React from "react";
import { useNavigate } from "react-router";
import { Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { useDeleteMultiProjectMutation } from "fond/api";
import { Folder } from "fond/types";
import { Modal } from "fond/widgets";

interface IProps {
  cityPlannerFolder: Folder;
  onClose(): void;
}

const DeleteCityPlannerModal: React.FC<IProps> = ({ cityPlannerFolder, onClose }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [deleteMultiProject, { isLoading }] = useDeleteMultiProjectMutation();

  /**
   * Callback function for the Create button within the modal actions
   */
  const handleOnClick = async (event: React.MouseEvent<EventTarget>) => {
    if (cityPlannerFolder.MultiProject?.ID) {
      try {
        await deleteMultiProject(cityPlannerFolder.MultiProject);
        enqueueSnackbar("The market lens project has been deleted");
        onClose();
        // Navigate to the updated folder
        if (cityPlannerFolder?.ParentID) {
          navigate(`/folders/${cityPlannerFolder?.ParentID}/`);
        }
      } catch (e) {
        enqueueSnackbar("Project deletion failed. Please try again...");
      }
    }
  };

  return (
    <Modal
      open
      header="Delete this market lens project"
      data-testid="delete-city-planner-modal"
      content={
        <>
          <Typography>
            Are you sure you wish to delete the market lens project <strong>{cityPlannerFolder.MultiProject?.Name}</strong>?
          </Typography>
          <Typography>The project & all content within will be removed for all users.</Typography>
        </>
      }
      actions={
        <>
          <Button data-testid="delete-city-planner-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="delete-city-planner-save-button" color="destructive" onClick={handleOnClick} loading={isLoading}>
            Delete
          </LoadingButton>
        </>
      }
    />
  );
};

export default DeleteCityPlannerModal;
