import { listItemIconClasses, MenuItem, styled } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)`
  white-space: normal;
  max-width: 45ch;
  align-items: start;

  .${listItemIconClasses.root} {
    padding-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;
