import { Accordion, styled, tableCellClasses, tableContainerClasses } from "@mui/material";

export const StyledAccordion = styled(Accordion)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  overflow: hidden; // Hide the (lack of) border radius of the content

  .${tableContainerClasses.root} {
    border: 0;
    border-radius: 0;

    // When we use a table inside an accordion, we want to make sure the table
    // rows are inverted from their usual style to make it easier to read
    & .${tableCellClasses.head} {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }

    & tr:nth-of-type(even) {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
    & tr:nth-of-type(odd) {
      background-color: ${({ theme }) => theme.palette.biarri.secondary.paleGrey};
    }
  }
`;
