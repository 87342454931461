import { LinearProgress, Tooltip, Typography } from "@mui/material";
import { get } from "lodash";

import { Table, TableBody, TableCell, TableHead, TableRow } from "ui";

import { AssessConfiguration, ScoreFactor } from "fond/types";

import { assessConfigurationHasValues, getFactorScoringInputConfig, ScoringInputConfig } from "./scoringInputConfiguration";

type ScoringValuesProps = {
  config?: AssessConfiguration;
  factor: ScoreFactor;
  title: string;
};

export const ScoringValues: React.FC<ScoringValuesProps> = ({ config, factor, title }: ScoringValuesProps) => {
  if (!assessConfigurationHasValues(config, factor)) {
    return null;
  }

  const getCriteraRow = ({ id, title: criteriaTitle, description }: ScoringInputConfig) => {
    const configValueForId = get(config, id);
    if (configValueForId == null) {
      return null;
    }

    return (
      <TableRow key={id}>
        <TableCell width={125}>
          <Tooltip title={description} placement="right-start">
            <span>{criteriaTitle}</span>
          </Tooltip>
        </TableCell>
        <TableCell>
          <LinearProgress variant="determinate" value={configValueForId * 100} />
        </TableCell>
      </TableRow>
    );
  };
  const rows = getFactorScoringInputConfig(factor).map(getCriteraRow);

  return (
    <>
      <Typography component="p" variant="h7" mb={1} mt={2}>
        {title}
      </Typography>
      <Table variant="ag-grid-compact">
        <TableHead>
          <TableRow>
            <TableCell>Criteria</TableCell>
            <TableCell width={100}>Importance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </>
  );
};
