import { ExpandMore } from "@mui/icons-material";
import { AccordionSummaryProps as BaseAccordionSummaryProps } from "@mui/material";

import { StyledAccordionSummary } from "./AccordionSummary.styles";

export type AccordionSummaryProps = BaseAccordionSummaryProps & {
  /**
   * An optional end adornment for the AccordionSummary component.
   */
  endAdornment?: React.ReactNode;
  /**
   * The ID of the Accordion component. This is required for accessibility to indicate the summary labels the details area.
   * If no `aria-controls` attribute is provide this will be used to generate the `aria-controls` attribute to indicate it controls the AccordionDetails component.
   * Note that MUI will automatically generate an ID for the AccordionDetails component based on the `aria-controls` value.
   */
  id: string;
};

/**
 * The accordion summary is used to provide a label for the accordion details and control the expansion state.
 *
 * @see [MUI AccordionSummary API](https://mui.com/material-ui/api/accordion-summary/)
 * @example
 * <AccordionSummary id="my-accordion-header" aria-controls="my-accordion-panel">
 *   Hello
 * </AccordionSummary>
 *
 * <AccordionSummary id="my-accordion" endAdornment={<span>End Adornment</span>}>
 */
export const AccordionSummary: React.FC<AccordionSummaryProps> = ({ children, endAdornment, ...props }: AccordionSummaryProps) => {
  let { "aria-controls": ariaControls } = props;
  if (!ariaControls) {
    ariaControls = `${props.id}-panel`;
  }

  return (
    <StyledAccordionSummary expandIcon={<ExpandMore fontSize="small" />} aria-controls={ariaControls} {...props}>
      {children}
      {endAdornment && <div className="accordion-end-adornment">{endAdornment}</div>}
    </StyledAccordionSummary>
  );
};
