import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  AdvancedFilterModel,
  ColDef,
  GridOptions,
  GridReadyEvent,
  HeaderValueGetterParams,
  JoinAdvancedFilterModel,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Check, Close } from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";

import { useGetReportsQuery } from "fond/api";
import { FullMultiReport, MultiReportCategory, Report, Store } from "fond/types";
import { GridId } from "fond/types/grids";
import { toSystemOfMeasurement } from "fond/utils";
import { formatFraction, formatNumber } from "fond/utils/number";
import { AgGrid, BlockSpinner } from "fond/widgets";

import { useStarred } from "../ItemMenu/hooks/useStarred";
import ReportRowMenu from "../ItemMenu/ReportRowMenu";
import EntityTypeCellRenderer from "../ProjectList/EntityCellRenderer";
import RowMenuCellRenderer from "../ProjectList/RowMenuCellRenderer";
import StatusCellRenderer from "../ProjectList/StatusCellRenderer";

interface IProps {
  gridRef: React.RefObject<AgGridReact>;
  multiReport: FullMultiReport;
  reports: Report[];
  sliderRange: number[] | null;
  multiReportCategory: MultiReportCategory;
  filteredNodes: Report[] | null;
  setFilterRange: Dispatch<SetStateAction<number[] | null>>;
  setFilteredNodes: Dispatch<SetStateAction<Report[] | null>>;
}

const reportStatusEntries = {
  PENDING: null, // Should not be observable through the API
  IN_PROGRESS: {
    text: "Generating",
    icon: <CircularProgress size={18} color="info" />,
  },
  COMPLETE: {
    text: "Generated",
    icon: <Check />,
  },
  ERROR: {
    text: "Error",
    icon: <Close />,
  },
};

const gridOptions: GridOptions = {
  defaultColDef: {
    resizable: true,
    sortable: true,
  },
  domLayout: "autoHeight",
  rowClass: "no-alternate-background",
  animateRows: true,
  suppressGroupRowsSticky: true,
  suppressContextMenu: true,
  sideBar: false,
  rowGroupPanelShow: "never",
  pagination: true,
  paginationPageSize: 50,
  loadingOverlayComponent: BlockSpinner,
};

const SubareaReportList: React.FC<IProps> = ({
  gridRef,
  multiReport,
  reports,
  sliderRange,
  multiReportCategory,
  filteredNodes,
  setFilterRange,
  setFilteredNodes,
}: IProps) => {
  const { isLoading, isFetching } = useGetReportsQuery(multiReport.ID);
  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>(undefined);
  const [menuEntity, setMenuEntity] = useState<Report | undefined>(undefined);
  const resultCount = filteredNodes?.length ?? 0;
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const { starred } = useStarred();
  const isStarred = useCallback((id: string) => starred.includes(id), [starred]);

  const openRowMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, entity?: Report) => {
    setMenuAnchor(e.currentTarget);
    setMenuEntity(entity);
  }, []);

  /**
   * Set the advanced filter parent element when the grid is ready
   */
  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.setGridOption("advancedFilterParent", document.getElementById("advancedFilterParent") ?? document.body);
    params.api.setGridOption("popupParent", document.getElementById("subareaReportList"));
  }, []);

  const closeRowMenu = () => setMenuAnchor(undefined);

  /**
   * Get the min and max values for the current category
   */
  const cellDataMinMax = useMemo(() => {
    const cellData: number[] = [];
    gridRef.current?.api.forEachNode((node) => {
      cellData.push(node.data[multiReportCategory]);
    });
    return {
      min: Math.min(...cellData),
      max: Math.max(...cellData),
    };
  }, [gridRef, multiReportCategory]);

  /**
   * Format the filter value to the proper value.
   */
  const formatFilterValue = useCallback(
    (value: number): number => {
      if (["Irr", "Roi"].includes(multiReportCategory)) {
        return value * 100;
      }
      if (multiReportCategory === "CostPerMeter") {
        return toSystemOfMeasurement(value, { from: multiReport.SystemOfMeasurement, to: "metric" });
      }
      return value;
    },
    [multiReportCategory, multiReport.SystemOfMeasurement]
  );

  /**
   * Format the header value to remove the units
   */
  const formatHeader = (params: HeaderValueGetterParams, header: string) => {
    if (params.location === "advancedFilter") {
      return header.replace(/\(.*?\)/g, "").trim();
    }
    return header;
  };

  /**
   * Monitor the AgGrid for filter changes
   */
  const onFilterChanged = () => {
    const filteredValue: number[] = [];
    const filteredRows: Report[] = [];
    gridRef.current?.api.forEachNodeAfterFilterAndSort((node) => {
      filteredRows.push(node.data);
      filteredValue.push(node.data[multiReportCategory]);
    });

    setFilterRange([...new Set(filteredValue)].sort((a, b) => a - b));
    setFilteredNodes(filteredRows);
  };

  /**
   * Helper function to recursively remove conditions matching the colId from the filter model
   */
  const cleanFilterModel = (filterModel: AdvancedFilterModel | undefined | null, colId: MultiReportCategory): AdvancedFilterModel[] => {
    const removeConditionsByColId = (conditions: AdvancedFilterModel[]): AdvancedFilterModel[] => {
      return conditions.reduce<AdvancedFilterModel[]>((filteredConditions, condition) => {
        // Handle nested conditions
        if (condition.filterType === "join") {
          filteredConditions.push({
            ...condition,
            conditions: removeConditionsByColId(condition.conditions),
          });
        }
        // Only keep conditions that don't match the target column ID
        else if (condition.colId !== colId) {
          filteredConditions.push(condition);
        }
        return filteredConditions;
      }, []);
    };

    if (!filterModel) {
      return [];
    }
    // If the filter model is not a join, meaning it's a single condition, return an empty array if the colId matches
    if (filterModel.filterType !== "join") {
      return filterModel.colId === colId ? [] : [filterModel];
    }
    return [
      {
        ...filterModel,
        conditions: removeConditionsByColId(filterModel.conditions),
      },
    ];
  };

  useEffect(() => {
    // Update the advanced filter model when the slider range changes
    if (!sliderRange || !gridRef.current) {
      return;
    }
    const existingFilterModel = gridRef.current.api.getAdvancedFilterModel();

    // Dynamically build the new filter model based on the slider range
    const newConditions: AdvancedFilterModel[] = [];
    if (sliderRange.length === 1) {
      newConditions.push({
        filterType: "number",
        colId: multiReportCategory,
        type: "equals",
        filter: formatFilterValue(sliderRange[0]),
      });
    }
    if (sliderRange.length > 1 && cellDataMinMax.min !== sliderRange.at(0)) {
      newConditions.push({
        filterType: "number",
        colId: multiReportCategory,
        type: "greaterThanOrEqual",
        filter: formatFilterValue(sliderRange[0]),
      });
    }
    if (sliderRange.length > 1 && cellDataMinMax.max !== sliderRange.at(-1)) {
      newConditions.push({
        filterType: "number",
        colId: multiReportCategory,
        type: "lessThanOrEqual",
        filter: formatFilterValue(sliderRange.at(-1)!),
      });
    }

    const newModel: JoinAdvancedFilterModel = {
      filterType: "join",
      type: "AND",
      conditions: [...cleanFilterModel(existingFilterModel, multiReportCategory), ...newConditions],
    };
    gridRef.current.api.setAdvancedFilterModel(newModel);
  }, [sliderRange, multiReportCategory, formatFilterValue, gridRef, cellDataMinMax.min, cellDataMinMax.max]);

  useEffect(() => {
    gridRef.current?.api?.setAdvancedFilterModel(null);
  }, [gridRef, multiReport, multiReportCategory]);

  const filterParams = useMemo(
    () => ({
      filterOptions: ["greaterThan", "greaterThanOrEqual", "lessThan", "lessThanOrEqual"],
    }),
    []
  );

  const columns: ColDef[] = useMemo(
    () => [
      {
        field: "Name",
        headerName: "Name",
        flex: 3,
        minWidth: 144,
        cellRenderer: EntityTypeCellRenderer,
        cellRendererParams: {
          currentUsername,
          isStarred,
        },
      },
      {
        field: "Status",
        headerName: "Status",
        width: 120,
        cellRendererSelector: ({ data }) => {
          if (data?.Status !== null) {
            return {
              params: { status: data.Status, statusEntries: reportStatusEntries },
              component: StatusCellRenderer,
            };
          }
          return undefined;
        },
      },
      {
        field: "Npv",
        headerValueGetter: (params: HeaderValueGetterParams) => formatHeader(params, "NPV ($)"),
        width: 120,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.Npv, 0),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "NetRevenue",
        headerValueGetter: (params: HeaderValueGetterParams) => formatHeader(params, "Revenue ($)"),
        width: 120,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.NetRevenue, 0),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "NetCost",
        headerValueGetter: (params: HeaderValueGetterParams) => formatHeader(params, "Cost ($)"),
        width: 120,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.NetCost, 0),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "Irr",
        headerValueGetter: (params: HeaderValueGetterParams) => formatHeader(params, "IRR (%)"),
        width: 96,
        // valueGetter will turn the value into a string, so we need to force the cell type to number.
        cellDataType: "number",
        valueGetter: (params: ValueGetterParams) => (params.data?.Irr ? params.data.Irr * 100 : params.data?.Irr),
        valueFormatter: (params: ValueFormatterParams) => formatFraction(params.data?.Irr, 0),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "Roi",
        headerValueGetter: (params: HeaderValueGetterParams) => formatHeader(params, "ROI (%)"),
        width: 96,
        // valueGetter will turn the value into a string, so we need to force the cell type to number.
        cellDataType: "number",
        valueGetter: (params: ValueGetterParams) => (params.data?.Roi ? params.data.Roi * 100 : params.data?.Roi),
        valueFormatter: (params: ValueFormatterParams) => formatFraction(params.data?.Roi, 0),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "TotalPassings",
        headerName: "Passings",
        width: 104,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.TotalPassings),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "TotalConnections",
        headerName: "Connections",
        width: 120,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.TotalConnections, 0),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "TotalFootprintMeters",
        headerValueGetter: (params: HeaderValueGetterParams) =>
          formatHeader(params, `Path ${multiReport.SystemOfMeasurement === "imperial" ? "(ft)" : "(m)"}`),
        width: 96,
        // valueGetter will turn the value into a string, so we need to force the cell type to number.
        cellDataType: "number",
        valueGetter: ({ data }: ValueGetterParams) =>
          toSystemOfMeasurement(data?.TotalFootprintMeters, { from: "metric", to: multiReport.SystemOfMeasurement }),
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumber(value, 0),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "CostPerMeter",
        headerValueGetter: (params: HeaderValueGetterParams) =>
          formatHeader(params, `Cost per ${multiReport.SystemOfMeasurement === "imperial" ? "foot" : "meter"} ($)`),
        width: 128,
        // valueGetter will turn the value into a string, so we need to force the cell type to number.
        cellDataType: "number",
        // Revert the system of measurement conversion because the path is used as the denominator
        valueGetter: ({ data }: ValueGetterParams) =>
          toSystemOfMeasurement(data?.CostPerMeter, { from: multiReport.SystemOfMeasurement, to: "metric" }),
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumber(value),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "CostPerPassing",
        headerValueGetter: (params: HeaderValueGetterParams) => formatHeader(params, "Cost per passing ($)"),
        width: 152,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.CostPerPassing, 0),
        filter: "agNumberColumnFilter",
        filterParams,
      },
      {
        field: "menu",
        headerName: "Menu",
        cellRenderer: RowMenuCellRenderer,
        cellRendererParams: {
          onClick: openRowMenu,
        },
        headerComponentParams: { displayName: "" },
        lockVisible: true,
        sortable: false,
        resizable: false,
        type: "rightAligned",
        width: 54,
      },
    ],
    [currentUsername, filterParams, isStarred, multiReport.SystemOfMeasurement, openRowMenu]
  );

  return (
    <>
      <Box display="flex" alignItems="center" mt={5} mb={2}>
        <TuneIcon sx={{ mr: 2 }} color="primary" />
        <Typography variant="subtitle1" component="span" fontWeight={700}>
          Report controls
        </Typography>
      </Box>
      <Box id="subareaReportList" display="flex" flexDirection="column" flexGrow={1} height="100%" position="relative">
        <Box mb={2} id="advancedFilterParent" />
        <Typography pl={1} pb={2} variant="body2">
          <strong>{resultCount}</strong> result{resultCount === 1 ? "" : "s"}
        </Typography>
        <Paper square>
          <AgGrid
            id={GridId.CITY_PLANNER_REPORT_SUMMARY}
            ref={gridRef}
            columnDefs={columns}
            rowData={reports}
            gridOptions={gridOptions}
            variant="outlined"
            containerProps={{ flexGrow: 1 }}
            loading={isLoading || isFetching}
            size="large"
            onFilterChanged={onFilterChanged}
            enableAdvancedFilter
            onGridReady={onGridReady}
            popupParent={document.getElementById("subareaReportList")}
          />
        </Paper>
      </Box>
      {menuEntity && <ReportRowMenu report={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />}
    </>
  );
};

export default SubareaReportList;
