import { createEntityAdapter, createSelector, EntityState } from "@reduxjs/toolkit";

import { apiSlice } from "fond/api/apiSlice";
import { Store, Workflow } from "fond/types";

type GetWorkflowsResponse = {
  Workflows: Workflow[];
};

export const workflowsAdaptor = createEntityAdapter<Workflow>({
  // Note that we are using the Project ID as the entity ID.
  // There can never be more than one workflow per project
  selectId: (entity: Workflow): string => entity.Version.Project.ID,
});
const workflowsInitialState = workflowsAdaptor.getInitialState();

export const workflowSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getWorkflows: build.query<EntityState<Workflow>, void>({
      query: () => "/v2/workflows",
      transformResponse: (response: GetWorkflowsResponse) => workflowsAdaptor.setAll(workflowsInitialState, response.Workflows),
    }),
  }),
});

export const { useGetWorkflowsQuery } = workflowSlice;

const selectWorkflowsResult = workflowSlice.endpoints.getWorkflows.select();
export const selectWorkflowsData = createSelector(selectWorkflowsResult, (workflowsResult) => workflowsResult.data);

export const {
  selectAll: selectAllWorkflows,
  selectById: selectWorkflowByProjectId,
  selectEntities: selectWorkflowEntities,
} = workflowsAdaptor.getSelectors((state: Store) => selectWorkflowsData(state) ?? workflowsInitialState);
