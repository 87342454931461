import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { selectFolderById, useGetProjectsQuery } from "fond/api";
import { useUpdateSubareaScores } from "fond/cityPlanner/useUpdateSubareaScores";
import MapProvider from "fond/map/MapProvider";
import { CityPlannerList } from "fond/projects/CityPlanner";
import CityPlannerFolderMap from "fond/projects/CityPlanner/CityPlannerFolderMap";
import CityPlannerInfoGrid from "fond/projects/CityPlanner/CityPlannerInfoGrid";
import { MultiProject, Store } from "fond/types";
import SkeletonLoadingGrid from "fond/widgets/SkeletonLoadingGrid";
import { ZoomableGridCard } from "fond/widgets/ZoomableGridCard";

interface RouteParams {
  multiProjectFolderId: string;
}

interface IProps {
  multiProject?: MultiProject;
  multiProjectLoaded?: boolean;
}

/**
 * A main page content displaying market lens projects.
 * Includes a DataGrid supporting column sorting and pagination
 */
const CityPlannerFolderPageContent: React.FC<IProps> = ({ multiProject, multiProjectLoaded }) => {
  const { multiProjectFolderId } = useParams<keyof RouteParams>();
  const folder = useSelector((state: Store) => (multiProjectFolderId ? selectFolderById(state, multiProjectFolderId) : undefined));

  // Keep polling until all projects are in a terminal state.
  const allFinished = multiProject?.Areas?.every((area) => ["COMPLETE", "ERROR"].includes(area?.ImportStatus?.State ?? "")) ?? false;
  useGetProjectsQuery(undefined, { pollingInterval: allFinished ? 0 : 30_000 });

  useUpdateSubareaScores(multiProject);

  if (!multiProject || !multiProjectLoaded)
    return (
      <SkeletonLoadingGrid
        items={[
          { height: 300, sizes: { xs: 12, sm: 6 } },
          { height: 300, sizes: { xs: 12, sm: 6 } },
          { height: 300, sizes: { xs: 12, sm: 12 } },
        ]}
      />
    );

  return (
    <>
      <Typography>{multiProject?.Description}</Typography>
      <Grid container spacing={2.5} sx={{ mt: 2 }}>
        {multiProject && <CityPlannerInfoGrid id={multiProject.ID} project={multiProject} />}
        <ZoomableGridCard breakpoints={{ lg: 6, xs: 12 }} title="Project map" height={320}>
          <MapProvider mapStyle="monochrome">{folder?.MultiProject?.ID && <CityPlannerFolderMap cityId={folder?.MultiProject?.ID} />}</MapProvider>
        </ZoomableGridCard>
      </Grid>
      <CityPlannerList />
    </>
  );
};

export default CityPlannerFolderPageContent;
