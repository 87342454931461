export enum Widget {
  CityDesign = "cityDesign",
  CityFeaturesTable = "cityFeaturesTable",
  CityMap = "cityMap",
  CityProperties = "cityProperties",
  Comments = "comments",
  DesignPanel = "design",
  ErrorLog = "errorLog",
  FeaturesList = "featuresList",
  Legend = "legend",
  Map = "map",
  MapLayout = "mapLayout",

  Versions = "versions",
  Properties = "properties",
}

export enum LayoutNodeType {
  Border = "border",
  Row = "row",
  Tab = "tab",
  TabSet = "tabset",
}

export enum LayoutNodeLocation {
  Bottom = "bottom",
  Top = "top",
  Left = "left",
  Right = "right",
}

export type LayoutNode = {
  /**
   * Attributes of a layout node, some of them are only related to a particular node type.
   */
  id: string;
  type: LayoutNodeType;
  active?: boolean;
  width?: number;
  weight?: number;
  selected?: number;
  order?: number;
  maximized?: boolean;
  config?: any;
  name?: string;
  size?: number;
  location?: LayoutNodeLocation;
  parentId?: string;
  children?: string[] | LayoutNode[];
};

export type LayoutNodeResponse = {
  /**
   * Attributes of a layout node in the response.
   */
  ID: string;
  Type: LayoutNodeType;
  Active?: boolean;
  Width?: number;
  Weight?: number;
  Selected?: number;
  Order?: number;
  Maximized?: boolean;
  Configs?: any;
  Name?: string;
  Size?: number;
  Location?: LayoutNodeLocation;
  Parent?: string;
  Children?: string[];
};

export const UserLayoutOrder = ["border_bottom", "border_left", "border_right", "row_main"];
