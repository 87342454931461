import React from "react";
import { useSnackbar } from "notistack";

import { useDeleteMultiProjectMutation, useDeleteProjectMutation, useDeleteReportMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { Folder, OkResponse, Project, Report, ResourceEntity } from "fond/types";
import DataGridToolbar from "fond/widgets/DataGrid/DataGridToolbar";

import ProjectListToolbarActions from "./ProjectListToolbarActions";

interface IProps {
  /**
   * The currently selected entities within the project list.
   */
  selectedItems: Array<Folder | Project | Report>;
  /**
   * Callback function for indicating the user wants to clear the current project list selections.
   */
  onClear(): void;
  /**
   * Callback function for indicating the user has completed an action on the current selections.
   */
  onComplete(): void;
}

const ProjectListToolbar: React.FC<IProps> = ({ selectedItems, onClear, onComplete }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteProject] = useDeleteProjectMutation();
  const [deleteReport] = useDeleteReportMutation();
  const [deleteMultiProject] = useDeleteMultiProjectMutation();

  /**
   * Callback function to bulk delete the select projects that the user has permission to delete.
   */
  const handleOnDelete = async (items: ResourceEntity[]) => {
    const deletePromises: Promise<OkResponse>[] = [];

    items.forEach((item) => {
      if (item.EntityType === "project") {
        deletePromises.push(
          deleteProject(item)
            .unwrap()
            .then((response) => {
              mixpanel.track("Deleted project", { projectId: item.ID });
              return response;
            })
        );
      } else if (item.EntityType === "folder" && item.MultiProject) {
        deletePromises.push(
          deleteMultiProject(item.MultiProject)
            .unwrap()
            .then((response) => {
              mixpanel.track("Market Lens", "Delete", "Delete project", { projectId: item.ID });
              return response;
            })
        );
      } else if (item.EntityType === "report") {
        deletePromises.push(
          deleteReport(item)
            .unwrap()
            .then((response) => {
              mixpanel.track("Report", "Delete", "Deleted report", { reportId: item.ID });
              return response;
            })
        );
      }
    });

    await Promise.allSettled(deletePromises)
      .then((results) => {
        const anyRequestFailed = results.some((result) => result.status === "rejected" || result.value.Message !== "OK");

        if (anyRequestFailed) {
          enqueueSnackbar("Some items failed to delete. Please try again...", { variant: "error" });
        } else {
          enqueueSnackbar("Items deleted successfully.");
        }
      })
      .catch((error) => {
        enqueueSnackbar("Some items failed to delete. Please try again...", { variant: "error" });
      });
    onComplete();
  };

  return (
    <DataGridToolbar
      selected={selectedItems.length}
      actions={<ProjectListToolbarActions selectedItems={selectedItems} onDelete={handleOnDelete} />}
      onClear={onClear}
    />
  );
};

export default ProjectListToolbar;
