import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectFoldersByParentId, selectProjectsByParentId, selectReportsByParentId } from "fond/api";
import { useContents } from "fond/hooks/useContents";
import { Store } from "fond/types";

import { BaseProjectList } from "./BaseProjectList";

interface RouteParams {
  folderId: string;
}

/**
 * Folders content component that renders contents within a folder
 */
const FoldersProjectList: React.FC = () => {
  const { folderId } = useParams<keyof RouteParams>();
  const folders = useSelector((state: Store) => selectFoldersByParentId(state, folderId));
  const projects = useSelector((state: Store) => selectProjectsByParentId(state, folderId));
  const reports = useSelector((state: Store) => selectReportsByParentId(state, folderId));

  return <BaseProjectList items={[...folders, ...projects, ...reports]} />;
};

export default FoldersProjectList;
