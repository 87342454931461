import React, { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ICellRendererParams } from "@ag-grid-community/core";
import {
  AssessmentOutlined,
  CameraOutlined,
  Description,
  Folder as FolderIcon,
  FolderShared,
  ImageAspectRatio,
  InsertChart,
  InsertDriveFileOutlined,
  People,
  Star,
} from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

import { ResourceEntity } from "fond/types";

import WriteLockedWarning from "../WriteLockedWarning";

import { CellWrapper, Link } from "./entityCellRenderer.styles";

interface IProps extends ICellRendererParams<ResourceEntity> {
  currentUsername: string;
  isStarred(id?: string): boolean;
}

const EntityTypeCellRenderer: React.FC<IProps> = memo(({ currentUsername, data, isStarred }: IProps) => {
  const starred = isStarred(data?.ID);
  const fontWeight = data?.EntityType === "folder" && !data.MultiProject ? 500 : 400;
  const creatorEmail = data?.EntityType === "report" ? data?.Creator.Email : data?.Creator;

  const getIcon = (): JSX.Element => {
    if (data?.EntityType === "folder" && data.MultiProject) return <CameraOutlined color="primary" />;
    if (data?.EntityType === "folder" && data.MultiReport) return <CameraOutlined color="tertiary" />;
    if (data?.EntityType === "folder" && creatorEmail !== currentUsername) return <FolderShared color="secondary" />;
    if (data?.EntityType === "folder") return <FolderIcon color="secondary" />;
    if (data?.EntityType === "report" && data.Type === "financial_analytics_imported") return <AssessmentOutlined color="tertiary" />;
    if (data?.EntityType === "report") return <InsertChart color="tertiary" />;
    if (data?.EntityType === "project" && data?.SubType === "planner") return <Description color="primary" />;
    if (data?.EntityType === "project" && data?.SubType === "collaboration") return <InsertDriveFileOutlined color="primary" />;
    if (data?.EntityType === "project" && data?.SubType === "design") return <ImageAspectRatio color="primary" />;
    return <span />;
  };

  const getUrl = (): string => {
    if (data?.EntityType === "folder" && data.MultiProject) return `/cities/${data?.ID}`;
    if (data?.EntityType === "folder") return `/folders/${data?.ID}`;
    if (data?.EntityType === "project") return `/project/${data?.ID}`;
    if (data?.EntityType === "report") return `/reports/${data?.ID}`;

    return "";
  };

  return (
    <CellWrapper data-testid={`${data?.EntityType}-title`}>
      {getIcon()}
      <Link component={RouterLink} to={getUrl()} sx={{ fontWeight }} data-testid="entity-name">
        {(data?.EntityType === "folder" || data?.EntityType === "report") && data.Name}
        {data?.EntityType === "project" && data.ProjectName}
      </Link>
      {currentUsername !== creatorEmail && (
        <Box display="flex" ml={1} alignItems="center">
          <Tooltip title={`Created by: ${creatorEmail}`}>
            <People color="action" fontSize="small" />
          </Tooltip>
        </Box>
      )}
      {starred && <Star color="action" fontSize="small" sx={{ marginLeft: 1 }} />}
      {data?.EntityType === "project" && data.WriteLocked && <WriteLockedWarning />}
    </CellWrapper>
  );
});

EntityTypeCellRenderer.displayName = "EntityTypeCellRenderer";

export default EntityTypeCellRenderer;
