import { AccordionProps as BaseAccordionProps } from "@mui/material";

import { AccordionDetails } from "./AccordionDetails";
import { AccordionSummary } from "./AccordionSummary";

import { StyledAccordion } from "./Accordion.styles";

export type AccordionProps = BaseAccordionProps & {
  /**
   * The label to display in the `AccordionSummary` component. If this is provided the `id` prop must also be provided.
   */
  label?: string;
  /**
   * The ID of the Accordion component. If a label is provided, this ID will be used to generate the `id` and `aria-controls` for the AccordionSummary.
   */
  id?: string;
  /**
   * An optional end adornment to display in the `AccordionSummary` component. (Only used if the `label` and `id` are provided)
   */
  endAdornment?: React.ReactNode;
};

/**
 * Render an Accordion component based on provided props.
 * If the `label` and `id` props are provided, the Accordion will automatically render the `AccordionSummary` component, and the
 * children will be rendered inside an `AccordionDetails` component.
 *
 * If you need explicit control over the `AccordionSummary` and `AccordionDetails` components, you can render them manually.
 *
 * @see [MUI Accordion docs](https://mui.com/material-ui/react-accordion/)
 *
 * @example
 * <Accordion label="Accordion Label" id="accordion-id">
 *   This is the content
 * </Accordion>
 *
 * <Accordion>
 *   <AccordionSummary id="accordion-id-header" aria-controls="accordion-id-panel">Accordion Label</AccordionSummary>
 *   <AccordionDetails>This is the content</AccordionDetails>
 * </Accordion>
 */
export const Accordion: React.FC<AccordionProps> = ({ children, label, id, endAdornment, ...props }: AccordionProps) => {
  let summary: React.ReactNode = null;
  let content: React.ReactNode = children;
  if (label && !id) {
    // if a label is provided we will render the Summary component automatically,
    // however this also requires an ID to be provided for accessibility.
    console.error("FOND Accordion: If a label is provided, an ID must also be provided");
    return null;
  }

  if (label && id) {
    summary = (
      <AccordionSummary aria-controls={`${id}-panel`} id={`${id}-header`} endAdornment={endAdornment}>
        {label}
      </AccordionSummary>
    );
    // The summary is rendered automatically the details can be automatically rendered as well
    content = <AccordionDetails>{children}</AccordionDetails>;
  }

  return (
    <StyledAccordion id={id} elevation={0} disableGutters {...props}>
      {summary}
      {content}
    </StyledAccordion>
  );
};
