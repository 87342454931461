import { useState } from "react";
import * as React from "react";
import { Share as ShareIcon } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, MenuProps, Typography } from "@mui/material";

import Share from "fond/share/Share";
import { Folder } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { replaceAllSpecialCharacters } from "fond/utils/strings";

import StarMenuItem from "./StarMenuItem";

interface CityReportRowMenuProps extends Pick<MenuProps, "anchorEl"> {
  /**
   * The city report folder the menu actions relate to
   */
  cityReportFolder: Folder;
  onMenuClose(event: React.MouseEvent<EventTarget>): void;
  open?: boolean;
}

type cityReportModal = "share";

const CityReportRowMenu: React.FC<CityReportRowMenuProps> = ({ cityReportFolder, onMenuClose, anchorEl, open = true }: CityReportRowMenuProps) => {
  const [showModal, setShowModal] = useState<cityReportModal>();

  if (!cityReportFolder) return null;

  /**
   * Generic Callback function that updates any of the modal open states and closes the menu
   */
  const openModal = (modalType: cityReportModal) => (event: React.MouseEvent<EventTarget>) => {
    onMenuClose(event);
    setShowModal(modalType);
  };

  const closeModal = () => {
    setShowModal(undefined);
  };

  return (
    <Menu
      data-testid={`project-list-item-menu-${replaceAllSpecialCharacters(cityReportFolder.Name, "")}`}
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl && open}
      onClose={onMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      {/**
       * TODO - Future ticket
       * Edit
       */}

      <MenuItem
        aria-label="Share Market Lens"
        onClick={openModal("share")}
        data-testid="share-folder-button"
        disabled={!permissionCheck(cityReportFolder.Permission.Level, Actions.FOLDER_SHARE_VIEW)}
      >
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <Typography variant="inherit">Share</Typography>
      </MenuItem>

      {/**
       * TODO - Future ticket
       * DELETE
       */}

      <StarMenuItem onMenuClose={onMenuClose} resource={cityReportFolder} />
      {showModal === "share" && <Share resource={cityReportFolder} onClose={closeModal} />}
    </Menu>
  );
};

export default CityReportRowMenu;
