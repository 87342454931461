import { useCallback, useMemo, useRef, useState } from "react";
import { Form, FormSpy } from "react-final-form";
import { Alert, Box, Button, Tabs, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { FormApi, FormState } from "final-form";
import arrayMutators from "final-form-arrays";
import { defer, difference, uniq } from "lodash";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui/LoadingButton";

import { useCreateMultiReportMutation, useGenerateMultiReportMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { FullReport, MultiProject } from "fond/types";
import { setValue } from "fond/utils/formMutators";
import { ReportDetails } from "fond/views/Report/ReportSettings/FormSections/ReportDetails";
import { ReportGeneralSettings } from "fond/views/Report/ReportSettings/FormSections/ReportGeneralSettings";
import { useFormValidation } from "fond/views/Report/ReportSettings/hooks/useFormValidation";
import { ImportReportDialog } from "fond/views/Report/ReportSettings/ImportReportDialog";
import { OverheadCostAllocation } from "fond/views/Report/ReportSettings/OverheadCostAllocation";
import { ReportRevenueFormFields } from "fond/views/Report/ReportSettings/ReportRevenueFormFields";
import { Disclaimer } from "fond/views/Report/ReportSettings/ReportSettingsForm.styles";
import { ResourceAllocationFields } from "fond/views/Report/ReportSettings/ResourceAllocationFields";
import { ReportFormConfiguration, ReportFormData, RevenueFormConfiguration } from "fond/views/Report/types";
import {
  mergeRevenueConfigurations,
  transformDemandToRevenue,
  transformReportConfigurationToReportForm,
  transformReportFormToReportSettings,
} from "fond/views/Report/util";
import { ConfirmModal, Modal } from "fond/widgets";

import { ReportConfigurationTabPanel } from "./ReportConfigurationTabPanel";

import { ReportConfigurationForm, ReportConfigurationTab } from "./ReportConfigurationModal.styles";

type ReportConfigurationModalProps = {
  project: MultiProject;
  open: boolean;
  onClose(): void;
};

function a11yTabProps(id: string) {
  return {
    id: `report-config-tab-${id}`,
    "aria-controls": `report-config-tabpanel-${id}`,
  };
}

type TabLabel = "General" | "Revenue" | "OPEX" | "Resource allocation";
type TabDefinition = {
  id: number;
  name: TabLabel;
  content: React.ReactNode;
  fields: string[]; // fields or field groups to monitor for validation problems in this tab
};

export const ReportConfigurationModal: React.FC<ReportConfigurationModalProps> = ({ project, open, onClose }: ReportConfigurationModalProps) => {
  let formApi: FormApi<ReportFormData> | null = null;
  const formValidation = useFormValidation();
  const { enqueueSnackbar } = useSnackbar();
  const [createMultiReport, { isLoading: isSavingMultiReport }] = useCreateMultiReportMutation();
  const [generateMultiReport, { isLoading: isGeneratingMultiReport }] = useGenerateMultiReportMutation();
  const existingRevenueConfiguration = useRef<RevenueFormConfiguration[] | null>([]);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  // We use a ref so that we can update this state in the validation function without causing a re-render
  const tabsErrorState = useRef<{ id: number; hasError: boolean }[]>([]);
  const [tabsWithErrors, setTabsWithErrors] = useState<number[]>([]);

  const tabs: TabDefinition[] = useMemo(
    () => [
      {
        id: 0,
        name: "General",
        content: (
          <>
            <Box pb={3} maxWidth={600}>
              <Button type="button" size="small" variant="outlined" onClick={() => setIsImportModalOpen(true)} sx={{ ml: "auto", mb: 3, px: 2 }}>
                Import configuration from an existing report
              </Button>

              <ReportDetails />
            </Box>
            <ReportGeneralSettings />
          </>
        ),
        fields: ["Name", "Description", "ReportConfiguration.PlanningHorizon", "ReportConfiguration.StartTime", "ReportConfiguration.DiscountRate"],
      },
      {
        id: 1,
        name: "Revenue",
        content: <ReportRevenueFormFields />,
        fields: ["ReportConfigurationValidation"],
      },
      {
        id: 2,
        name: "OPEX",
        content: (
          <>
            <Typography variant="caption">Customize the overhead costs by entering your own values in the table below.</Typography>
            <OverheadCostAllocation />
          </>
        ),
        // TODO: These fields are dynamic, can we monitor specific ones or should we add a combined validation?
        fields: ["ReportConfiguration.CostConfiguration"],
      },
      {
        id: 3,
        name: "Resource allocation",
        content: (
          <Box mt={2} display="flex" columnGap={6}>
            <ResourceAllocationFields />
          </Box>
        ),
        fields: ["ReportConfiguration.ResourceAllocation.ExpansionRateCombinedValue"],
      },
    ],
    []
  );

  // generate an object to map fields to tabs
  // e.g. { "Name": 0, "ReportConfigurationValidation": 1, ... }
  // we'll use this to determine which tab a field belongs to when we need to highlight errors
  const fieldsToTabMap: Record<string, number> = useMemo(
    () => tabs.reduce((tabFieldMap, { fields, id }) => fields.reduce((fieldMap, field) => ({ ...fieldMap, [field]: id }), tabFieldMap), {}),
    [tabs]
  );

  const resetTabErrors = () => {
    tabsErrorState.current = tabs.map((tab) => ({ id: tab.id, hasError: false }));
  };

  const [confirmCloseWithoutSaving, setConfirmCloseWithoutSaving] = useState(false);

  const getFieldsToMonitorByTab = useCallback(() => {
    const registeredFields = formApi!.getRegisteredFields().filter((field) => Object.keys(fieldsToTabMap).some((f) => field.startsWith(f)));
    // use the fieldsToTabMap to map the fields to the tabs
    const fieldsByTab: Record<number, string[]> = tabs.reduce((acc, tab) => {
      const tabFields = registeredFields.filter((rField) => tab.fields?.some((field) => rField.startsWith(field)));
      return { ...acc, [tab.id]: tabFields };
    }, {});
    return fieldsByTab;
  }, [fieldsToTabMap, formApi, tabs]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const initialValues: Partial<ReportFormData> = useMemo(
    () => ({
      Name: "",
      Description: "",
      Project: project,
      Version: null,
      ReportConfiguration: { StepFrequency: "quarterly", StartTime: dayjs().toString(), CostConfiguration: { Name: "OverheadCost", Costs: [] } },
    }),
    [project]
  );

  const tabHasErrors = (tabId: number) => {
    return tabsWithErrors.includes(tabId);
  };

  const formChangeHandler = ({ submitFailed }: FormState<ReportFormData>, form: FormApi<ReportFormData, Partial<ReportFormData>>) => {
    // defer to allow the form values to propagate to the form state.
    defer(() => {
      const fieldsToMonitorByTab = getFieldsToMonitorByTab();
      const errorsOnTabs = [...tabsWithErrors];
      if (submitFailed) {
        // highlight errors on all tabs
        const errorTabs = Object.entries(fieldsToMonitorByTab)
          .map(([tab, fields]) => {
            const errorsInTab = fields.some((field) => {
              const fieldState = form.getFieldState(field as keyof ReportFormData);
              return fieldState?.valid === false && fieldState?.touched === true;
            });
            if (errorsInTab) {
              return Number(tab);
            } else {
              return null;
            }
          })
          .filter((x): x is number => x !== null);

        errorsOnTabs.push(...uniq(errorTabs));
      }

      // find problems on the current tab
      const currentTabHasErrors = fieldsToMonitorByTab[selectedTab].some((field) => {
        const fieldState = form.getFieldState(field as keyof ReportFormData);
        return fieldState?.valid === false && fieldState?.touched === true;
      });

      if (currentTabHasErrors) {
        if (!errorsOnTabs.includes(selectedTab)) {
          errorsOnTabs.push(selectedTab);
        }
      } else if (errorsOnTabs.includes(selectedTab)) {
        // note we explicitly splice here to modify the array in place.
        errorsOnTabs.splice(errorsOnTabs.indexOf(selectedTab), 1);
      }

      // check the difference in both arrays to determine if we need to update the state
      const diff = difference(errorsOnTabs, tabsWithErrors).concat(difference(tabsWithErrors, errorsOnTabs));
      if (diff.length) {
        setTabsWithErrors(uniq(errorsOnTabs));
        // select the first tab with errors
        if (errorsOnTabs.length) {
          setSelectedTab(errorsOnTabs[0]);
        }
      }
    });
  };

  const generateReport = async (multiReportId: string) => {
    try {
      await generateMultiReport(multiReportId).unwrap();
      enqueueSnackbar("Report generation commenced", { variant: "success" });
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar("Failed to generate report. Make sure there is at least one finished design in this project's subareas.", { variant: "error" });
    }
  };

  const onSubmit = async (values: ReportFormData) => {
    const { Name, Description, ReportConfiguration } = transformReportFormToReportSettings(values);
    try {
      const multiReport = await createMultiReport({
        Name,
        Description: Description ?? "",
        MultiProjectID: project.ID,
        ReportConfiguration,
      }).unwrap();
      // Track creation of a multi report
      mixpanel.track("MultiReport", "Create", "MultiReport Created", { multiReportId: multiReport.ID });
      enqueueSnackbar("Report created successfully", { variant: "success" });
      await generateReport(multiReport.ID);
      closeConfigurationModal();
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar("Failed to create report", {
        variant: "error",
        action: (
          <Button onClick={() => onSubmit(values)} color="inherit">
            Retry
          </Button>
        ),
      });
    }
  };

  const onImport = async (reportToImport?: FullReport) => {
    if (!formApi) {
      return;
    }
    const formValues = formApi.getState().values;
    if (!reportToImport || !reportToImport?.ReportConfiguration) {
      return;
    }

    const reportFormConfig = transformReportConfigurationToReportForm(reportToImport?.ReportConfiguration, project?.SystemOfMeasurement);
    existingRevenueConfiguration.current = reportFormConfig?.Revenue?.RevenueConfigurations ?? [];
    // use the demand model from the existing report to merge the revenue configurations against
    const demandModel = project.Architecture?.Demand;
    existingRevenueConfiguration.current = transformDemandToRevenue(demandModel?.DemandConfigurations.filter(({ Ignore }) => !Ignore));

    // Note that here we have reversed the order of the arguments of the function. Normally you would pass in
    // the existing config first followed by the new config, however in this case we want the new config to be
    // the first argument because we always want the merged configuration to match the demand model of the currently
    // selected project OR just match the selected report's revenue configuration if there is no project selected.
    const mergedRevenueConfigurations = mergeRevenueConfigurations(
      reportFormConfig?.Revenue?.RevenueConfigurations ?? null,
      existingRevenueConfiguration.current
    );

    const reportConfig: ReportFormConfiguration = {
      ...formValues.ReportConfiguration,
      ...reportFormConfig,
      Revenue: { RevenueConfigurations: mergedRevenueConfigurations || [] },
    };

    formApi.change("ReportConfiguration", reportConfig);
  };

  const gotoPreviousTab = () => {
    setSelectedTab(selectedTab - 1);
  };

  const gotoNextTab = () => {
    setSelectedTab(selectedTab + 1);
  };

  const handleConfirmOk = () => {
    setConfirmCloseWithoutSaving(false);
    closeConfigurationModal();
  };
  const handleConfirmCancel = () => {
    setConfirmCloseWithoutSaving(false);
  };

  const closeConfigurationModal = () => {
    onClose();
    // defer to avoid the UI updating before the modal closes.
    defer(() => {
      setSelectedTab(0);
      resetTabErrors();
    });
  };

  const onCloseHandler = () => {
    if (formApi!.getState().dirty) {
      setConfirmCloseWithoutSaving(true);
    } else {
      closeConfigurationModal();
    }
  };

  let actionsContent = (
    <Box p={2}>
      <Button type="button" color="primary" onClick={onCloseHandler} sx={{ mr: 1 }}>
        Cancel
      </Button>
      <Button type="button" color="primary" variant="outlined" disabled={selectedTab === 0} onClick={gotoPreviousTab} sx={{ mr: 1 }}>
        Back
      </Button>
      <Button type="button" color="primary" variant="contained" onClick={gotoNextTab}>
        Next
      </Button>
    </Box>
  );

  // on the last tab we show the disclaimer and save button
  if (selectedTab === tabs.length - 1) {
    actionsContent = (
      <Box flexDirection="column">
        <Typography color="GrayText" mb={2}>
          Ensure to input and review all data into the fields below before generating a report. These estimates serve as benchmarks for evaluating
          return on investments, but they do not ensure market success or specific revenue receipts. Use them as a tool to assess the costs and
          potential outcomes of your investments.
        </Typography>
        <Disclaimer sx={{ width: "100%", padding: 2 }}>
          <Typography>By clicking "Generate", I agree that all data is correct and reviewed as instructed above.</Typography>
          <Box>
            <Button type="button" color="primary" onClick={onCloseHandler} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button type="button" color="primary" variant="outlined" onClick={gotoPreviousTab} sx={{ mr: 1 }}>
              Back
            </Button>
            <LoadingButton
              type="submit"
              form="multiproject-report-configuration-form"
              color="primary"
              loading={isSavingMultiReport || isGeneratingMultiReport}
            >
              Generate
            </LoadingButton>
          </Box>
        </Disclaimer>
      </Box>
    );
  }

  const modalContent = (
    <Form<ReportFormData>
      initialValues={initialValues}
      // We validate on blur due to the complexity of the form & the performance
      // hit if validating onChange
      validateOnBlur
      validate={formValidation}
      onSubmit={onSubmit}
      // We use an empty subscription to increase form performance - preventing
      // the form from re-rendering every time a value changes
      // See: https://final-form.org/docs/react-final-form/examples/subscriptions
      subscription={{}}
      render={({ handleSubmit, form }) => {
        formApi = form;
        return (
          <ReportConfigurationForm onSubmit={handleSubmit} id="multiproject-report-configuration-form">
            <FormSpy<ReportFormData> onChange={(props) => formChangeHandler(props, form)} />
            <Alert severity="info" sx={{ mb: 2 }}>
              A market lens report will generate separate financial reports for all selected subareas. The configurations provided below will be
              applied to each subarea individually. For example, a total budget of $1M would mean that each subarea receives a budget of $1M.
            </Alert>

            <Tabs onChange={handleTabChange} value={selectedTab}>
              {tabs.map((tab) => (
                <ReportConfigurationTab
                  className={tabHasErrors(tab.id) ? "validation-error" : ""}
                  key={tab.id}
                  label={`${tab.name}${tabHasErrors(tab.id) ? "*" : ""}`}
                  {...a11yTabProps(tab.name)}
                />
              ))}
            </Tabs>
            {tabs.map((tab) => (
              <ReportConfigurationTabPanel key={tab.name} value={selectedTab} id={tab.id}>
                {tab.content}
              </ReportConfigurationTabPanel>
            ))}
          </ReportConfigurationForm>
        );
      }}
      mutators={{ setValue, ...arrayMutators }}
    />
  );

  return (
    <>
      <ConfirmModal
        open={confirmCloseWithoutSaving}
        content="Are you sure you want to discard your changes?"
        onConfirm={handleConfirmOk}
        onCancel={handleConfirmCancel}
      />
      {isImportModalOpen && <ImportReportDialog onImport={onImport} onClose={() => setIsImportModalOpen(false)} />}

      <Modal
        header="Report configuration"
        content={modalContent}
        open={open}
        onClose={onCloseHandler}
        actions={actionsContent}
        DialogContentProps={{ sx: { display: "flex" } }}
        PaperProps={{ style: { width: "80vw", height: "80vh", maxHeight: "800px", maxWidth: "1200px" } }}
      />
    </>
  );
};
