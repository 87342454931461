import convert from "convert-units";

/**
 * Converts square miles to square metres.
 */
export function sqMilesToSqMetres(squareMiles: number): number {
  return convert(squareMiles).from("mi2").to("m2");
}

/**
 * Converts square metres to square miles.
 */
export function sqMetresToSqMiles(squareMeters: number): number {
  return convert(squareMeters).from("m2").to("mi2");
}

export function sqMetresToSqKilometers(squareMeters: number): number {
  return convert(squareMeters).from("m2").to("km2");
}

export function sqMetresToSqFeet(squareMeters: number): number {
  return convert(squareMeters).from("m2").to("ft2");
}
