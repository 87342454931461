import { AccordionSummary, accordionSummaryClasses, styled } from "@mui/material";

export const StyledAccordionSummary = styled(AccordionSummary)`
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.biarri.secondary.paleGrey};
  min-height: auto;

  .${accordionSummaryClasses.content} {
    margin: ${({ theme }) => theme.spacing(1, 0)};
  }

  &.${accordionSummaryClasses.expanded} {
    min-height: auto;
  }

  .${accordionSummaryClasses.expandIconWrapper} {
    color: ${({ theme }) => theme.palette.biarri.secondary.activeGrey};
  }

  .accordion-end-adornment {
    margin-left: auto;
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;
