import MapboxDraw from "@mapbox/mapbox-gl-draw";
import convert from "convert-units";
import { Feature, MultiPolygon, Polygon } from "geojson";
import { sumBy } from "lodash";

import { bbox, difference, feature as toFeature, featureCollection, polygon as toPolygon } from "fond/turf";
import { MultiProject, MultiProjectArea, MultiProjectAreaStatistic, SystemOfMeasurement, SystemsOfMeasurement } from "fond/types";
import { isMultiPolygon, isPolygon } from "fond/types/geojson";
import { toBBox } from "fond/utils";

export type AreaErrorType = "prem_count_exceeded" | "name_duplication";

/**
 * Prevents a polygon from extending beyond another polygon
 */
export const clipOutside = (
  polygon: Feature<Polygon | MultiPolygon>,
  boundaryPolygon: Feature<Polygon | MultiPolygon>
): Feature<Polygon | MultiPolygon> => {
  let diffPoly: Feature<Polygon | MultiPolygon> | null = difference(polygon, boundaryPolygon);

  diffPoly = diffPoly ? difference(polygon, diffPoly) : polygon;

  return { ...diffPoly, id: polygon.id } as Feature<Polygon | MultiPolygon>;
};

/**
 * Converts a MultiPolygon into multiple Polygon features
 */
export const uncombine = (feature: Feature<MultiPolygon | Polygon>): Feature<Polygon>[] => {
  if (isMultiPolygon(feature)) {
    const polygons = feature.geometry.coordinates.map((coord) => toPolygon(coord, { ...feature.properties }));
    return polygons;
  } else if (isPolygon(feature)) {
    return [feature];
  }
  return [];
};

/**
 * Updates a property value on a feature currently being drawn
 */
export const setFeatureProperty = (drawControl: MapboxDraw, id: string, property: string, value: any): void => {
  if (drawControl) {
    drawControl.setFeatureProperty(id, property, value);

    // We refresh the features to force a redraw
    drawControl.set(drawControl.getAll());
  }
};

/**
 * Generates a bounding box based on the projects subarea boundaries.
 * @returns A turf.bbox converted to the format used by mapboxgl.Map
 */
export const getAreaBoundingBox = (areas: MultiProjectArea[]): [[number, number], [number, number]] | null => {
  // Create a feature collection for turf to use
  const collection = featureCollection(areas.map(({ Boundary }) => toFeature(Boundary)));
  const turfBBox = bbox(collection);

  // Convert to mapboxgl bbox
  return toBBox(turfBBox);
};

export const scoringPalette: Record<string | number, string> = {
  100: "#066E15",
  90: "#2D891D",
  80: "#55A525",
  70: "#7CC02C",
  60: "#A4DC34",
  50: "#CBF73C",
  40: "#FCFF4B",
  30: "#FBC044",
  20: "#FA803D",
  10: "#F94135",
  0: "#F8012E",
  NA: "grey",
};

export const getScoreColor = (score: number): string => {
  if (score > 100) return scoringPalette[100];
  if (score < 0) return scoringPalette.NA;

  // Get the nearest lower multiple of 10
  const lowerScore = Math.floor(score / 10) * 10;

  // Ensure the lower score is not less than 0
  const finalScore = Math.max(lowerScore, 0);

  return scoringPalette[finalScore];
};

export const mapboxScoring = [
  "step",
  ["to-number", ["get", "score"]],
  scoringPalette.NA,
  0,
  scoringPalette[0],
  10,
  scoringPalette[10],
  20,
  scoringPalette[20],
  30,
  scoringPalette[30],
  40,
  scoringPalette[40],
  50,
  scoringPalette[50],
  60,
  scoringPalette[60],
  70,
  scoringPalette[70],
  80,
  scoringPalette[80],
  90,
  scoringPalette[90],
  99,
  scoringPalette[100],
];

/**
 * Calculates an areas score based on the areas statistics & the weights the user has defined.
 *
 * @param inputs The importance weightings used to determine the score.
 * @param areaId ID of the area to be scored
 * @param statistics The statistics for all the subareas within the project.
 * @returns {number} The areas score.
 */
export const calculateScore = (inputs: MultiProject["AssessConfiguration"], areaId: string, statistics: MultiProjectAreaStatistic[]): number => {
  const stats = statistics.find(({ Area }) => Area.ID === areaId);
  if (!stats) return 0;

  // Extract the provider fiber coverage and address breakdown importance from the inputs
  const { ProviderFiberCoverageImportance, AddressBreakdownImportance, ...rest } = inputs ?? {
    ProviderFiberCoverageImportance: {},
    AddressBreakdownImportance: {},
  };
  const fiberCoverageImportance = { "AT&T": 0.5, Verizon: 0.5, "T-Mobile": 0.5, ...ProviderFiberCoverageImportance };

  // Default address breakdown Sdu to 0.5 if not present
  const addressBreakdownImportance = { Sdu: 0.5, ...AddressBreakdownImportance };

  // Add default values if the assessConfiguration has not yet been defined or is missing values.
  const importance = { AddressDensityImportance: 0.5, AddressUnderservedProportionImportance: 0.5, WeightedMedianIncomeImportance: 0.5, ...rest };

  const sum = sumBy(Object.values(importance)) + sumBy(Object.values(fiberCoverageImportance)) + sumBy(Object.values(addressBreakdownImportance));
  if (sum === 0) return 0;

  let score = 0;
  score += (importance.AddressDensityImportance / sum) * stats.AddressDensity.Rank;
  score += (importance.AddressUnderservedProportionImportance / sum) * stats.AddressUnderservedProportion.Rank;
  score += (importance.WeightedMedianIncomeImportance / sum) * stats.WeightedMedianIncome.Rank;

  score += (addressBreakdownImportance.Sdu / sum) * stats.AddressBreakdown.Sdu.Rank;

  // We want the score to decrease as coverage increases, so subtract the rank from 1
  score += (fiberCoverageImportance["AT&T"] / sum) * (1 - stats.ProviderFiberCoverage["AT&T"].Rank);
  score += (fiberCoverageImportance["T-Mobile"] / sum) * (1 - stats.ProviderFiberCoverage["T-Mobile"].Rank);
  score += (fiberCoverageImportance.Verizon / sum) * (1 - stats.ProviderFiberCoverage.Verizon.Rank);

  return 100 * score;
};

export const convertPath = (value: number, systemOfMeasurement?: SystemOfMeasurement): number =>
  convert(value)
    .from("m")
    .to(systemOfMeasurement === SystemsOfMeasurement.Imperial ? "ft" : "m");

export const convertDensity = (value: number, systemOfMeasurement?: SystemOfMeasurement): number =>
  value * (systemOfMeasurement === SystemsOfMeasurement.Imperial ? convert(1).from("mi2").to("m2") : convert(1).from("km2").to("m2"));

export const convertArea = (value: number, systemOfMeasurement?: SystemOfMeasurement): number =>
  convert(value)
    .from("m2")
    .to(systemOfMeasurement === SystemsOfMeasurement.Imperial ? "mi2" : "km2");
