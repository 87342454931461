import React, { useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import {
  ColDef,
  ColGroupDef,
  GetContextMenuItemsParams,
  GridOptions,
  MenuItemDef,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Directions, Highlight, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, TextField, Tooltip } from "@mui/material";
import { feature } from "@turf/helpers";
import mapboxgl from "mapbox-gl";

import { useGetMultiProjectQuery, useGetMultiProjectStatisticsQuery } from "fond/api";
import { zoomTo } from "fond/map/redux";
import { highlightFeatures, selectFeature } from "fond/project/redux";
import * as turf from "fond/turf";
import { MultiProjectArea, MultiProjectAreaStatistic, SystemsOfMeasurement } from "fond/types";
import { GridId } from "fond/types/grids";
import { sqMetresToSqKilometers, sqMetresToSqMiles } from "fond/utils/area";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { formatFractionPercent, formatInteger, formatNumber } from "fond/utils/number";
import { AgGrid, BlockSpinner } from "fond/widgets";

import { convertDensity, convertPath } from "../helper";

type Row = MultiProjectAreaStatistic & MultiProjectArea;

/**
 * To make sure the tooltip loads in the correct window.document
 * when the component is loaded within a floating window we
 * need to disable the use of a portal.
 */
const tooltipPopperProps = { disablePortal: true };

const gridOptions: GridOptions = {
  rowGroupPanelShow: "never",
  sideBar: false,
  pagination: false,
  rowSelection: {
    mode: "multiRow",
    checkboxes: false,
    headerCheckbox: false,
    enableClickSelection: true,
  },
  domLayout: "normal",
  getRowId: (params) => params.data.ID,
};

const FeaturesTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const multiProjectId = useAppSelector((state) => state.project.projectId);
  const { data: multiProject, isLoading: isLoadingProject } = useGetMultiProjectQuery(multiProjectId);
  const { data: statistics, isLoading: isLoadingStatistics } = useGetMultiProjectStatisticsQuery(multiProjectId);
  const [selectedFeatures, setSelectedFeatures] = useState<Partial<mapboxgl.MapboxGeoJSONFeature>[]>([]);
  const gridRef = useRef<AgGridReact | null>(null);

  const rowData: Row[] = useMemo(() => {
    if (!multiProject || !statistics) return [];
    return statistics.Areas.map((stats) => ({ ...stats, ...multiProject.Areas.find((a) => a.ID === stats.Area.ID) }) as Row);
  }, [multiProject, statistics]);

  const columns: Array<ColDef | ColGroupDef> = useMemo(() => {
    return [
      {
        field: "Name",
        headerName: "Name",
        flex: 1,
        minWidth: 125,
      },
      {
        field: "Score",
        headerName: "Score",
        width: 75,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.value, 0),
      },
      {
        field: "Boundary",
        headerName: `Area (${multiProject?.SystemOfMeasurement === SystemsOfMeasurement.Imperial ? "sq mi" : "sq km"})`,
        width: 125,
        valueGetter: (params: ValueGetterParams) =>
          multiProject?.SystemOfMeasurement === "imperial"
            ? sqMetresToSqMiles(turf.area(params.data.Boundary))
            : sqMetresToSqKilometers(turf.area(params.data.Boundary)),
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.value, 2),
      },

      {
        field: "StreetCounts.StreetIntersectionLengthMetres",
        headerName: `Paths (${multiProject?.SystemOfMeasurement === SystemsOfMeasurement.Imperial ? "ft" : "m"})`,
        width: 125,
        valueGetter: (params: ValueGetterParams) =>
          convertPath(params.data.StreetCounts.StreetIntersectionLengthMetres, multiProject?.SystemOfMeasurement),
        valueFormatter: (params: ValueFormatterParams) => formatInteger(params.value),
      },

      {
        headerName: "Addresses",
        children: [
          {
            field: "AddressCounts.AddressCount",
            headerName: "Count",
            width: 100,
            valueFormatter: (params: ValueFormatterParams) => formatNumber(params.value, 2),
          },
          {
            field: "AddressDensity.AddressesPerSquareMeters",
            headerName: `Density (${multiProject?.SystemOfMeasurement === SystemsOfMeasurement.Imperial ? "sq mi" : "sq km"})`,
            width: 125,
            valueGetter: (params: ValueGetterParams) =>
              convertDensity(params.data.AddressDensity.AddressesPerSquareMeters, multiProject?.SystemOfMeasurement),
            valueFormatter: (params: ValueFormatterParams) => formatNumber(params.value, 2),
          },
          {
            field: "AddressBreakdown.Sdu.Proportion",
            headerName: "SDU (%)",
            valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
            width: 125,
          },
          {
            field: "AddressUnderservedProportion.AddressUnderservedProportion",
            headerName: "Underserved (%)",
            valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
            width: 125,
          },
          {
            field: "WeightedMedianIncome.WeightedMedianIncomeDollars",
            headerName: "Median Income ($)",
            valueFormatter: (params: ValueFormatterParams) => formatNumber(params.value, 2),
            width: 145,
          },
        ],
      },
      {
        headerName: "Fiber coverage",
        children: [
          {
            field: "ProviderFiberCoverage.AT&T.FiberCoverageProportion",
            headerName: "AT&T",
            valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
            width: 100,
          },
          {
            field: "ProviderFiberCoverage.T-Mobile.FiberCoverageProportion",
            headerName: "T-Mobile",
            valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
            width: 100,
          },
          {
            field: "ProviderFiberCoverage.Verizon.FiberCoverageProportion",
            headerName: "Verizon",
            valueFormatter: (params: ValueFormatterParams) => formatFractionPercent(params.value),
            width: 100,
          },
        ],
      },
    ];
  }, [multiProject?.SystemOfMeasurement]);

  if (!multiProject) return <BlockSpinner />;

  /**
   * Provides a custom set of context menu items (right click on row)
   */
  const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    return [
      "copy",
      "copyWithHeaders",
      "separator",
      "export",
      "separator",
      {
        // custom item
        name: "Zoom to Feature",
        action: () => {
          if (params.node) {
            const { Boundary, ID } = params.node.data;
            const mapboxFeature: Partial<mapboxgl.MapboxGeoJSONFeature> = {
              ...feature(Boundary, { boundaryId: ID }, { id: ID }),
              source: "multiProject-source",
            };
            // Zoom to feature or the group of features
            dispatch(zoomTo([mapboxFeature]));
          }
        },
        icon: renderToString(<Directions style={{ verticalAlign: "middle", fontSize: "18px" }} />),
      },
    ];
  };

  /**
   * Callback function called when a row is double clicked
   * Feature is zoomed to & selected
   */
  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
    dispatch(zoomTo(selectedFeatures));
    dispatch(selectFeature(selectedFeatures[0]));
    dispatch(
      highlightFeatures({
        projectId: multiProjectId,
        features: selectedFeatures,
      })
    );
  };

  /**
   * Callback function called when row selection changes within the grid.
   * Enable zoom if at least one feature has geometry.
   */
  const onSelectionChanged = (event: SelectionChangedEvent<Row>) => {
    const features: Partial<mapboxgl.MapboxGeoJSONFeature>[] = [];
    const selectedNodes = event.api.getSelectedNodes();
    selectedNodes.forEach(({ data }) => {
      if (data?.Boundary) {
        const mapboxFeature: Partial<mapboxgl.MapboxGeoJSONFeature> = {
          ...feature(data.Boundary, { boundaryId: data.ID }, { id: data.ID }),
          source: "multiProject-source",
        };
        features.push(mapboxFeature);
      }
    });
    setSelectedFeatures(features);
  };

  const handleHighlight = () => {
    dispatch(
      highlightFeatures({
        projectId: multiProjectId,
        features: selectedFeatures,
      })
    );
  };

  /**
   * Callback function for zooming to selected features within the table
   */
  const handleZoomTo = () => {
    dispatch(zoomTo(selectedFeatures));
  };

  /**
   * Callback function that is passed the filter text as it changes
   */
  const handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    gridRef.current?.api?.setGridOption("quickFilterText", event.target.value ?? "");
  };

  return (
    <Box position="absolute" top={0} left={0} right={0} bottom={0} display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" alignItems="center" m={0.5}>
        <TextField
          variant="outlined"
          data-testid="ag-grid-external-input"
          margin="dense"
          name="search"
          onChange={handleOnFilterChange}
          placeholder="Search..."
          inputProps={{ sx: { p: 1, fontSize: 12 } }}
          InputProps={{
            startAdornment: <Search />,
          }}
          sx={{ m: 0 }}
        />
        <Box height="100%" mx={1}>
          <Divider orientation="vertical" />
        </Box>
        <ButtonGroup variant="outlined">
          <Tooltip title="Zoom to subarea" PopperProps={tooltipPopperProps}>
            <span>
              <Button
                variant="text"
                size="small"
                disabled={!selectedFeatures.length}
                onClick={handleZoomTo}
                startIcon={<Directions color={selectedFeatures.length ? "primary" : "disabled"} />}
              >
                Zoom
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Highlight Subareas" PopperProps={tooltipPopperProps}>
            <span>
              <Button
                variant="text"
                size="small"
                disabled={!selectedFeatures.length}
                onClick={handleHighlight}
                startIcon={<Highlight color={selectedFeatures.length ? "primary" : "disabled"} />}
              >
                Highlight
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>
      </Box>

      <Box flexGrow={1}>
        <AgGrid
          id={GridId.CITY_PLANNER_FEATURES_TABLE}
          ref={gridRef}
          gridOptions={gridOptions}
          getContextMenuItems={getContextMenuItems}
          variant="borderless"
          size="compact"
          columnDefs={columns}
          rowData={rowData}
          loading={isLoadingProject || isLoadingStatistics}
          onSelectionChanged={onSelectionChanged}
          onRowDoubleClicked={onRowDoubleClicked}
        />
      </Box>
    </Box>
  );
};

export default FeaturesTable;
