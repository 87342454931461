import chroma from "chroma-js";
import mapboxgl from "mapbox-gl";

import { Configuration, ConfigurationUpsert, MultiReportCategory, Report } from "fond/types";
import { FilterConfiguration, LayerStyle } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

import { FamilialLayerConfig, layerConfigTemplate } from "./configuration";
import { SubareaLayerId } from "./subareaConfiguration";

export enum CityReportLayerId {
  Npv = "cityReportAreaNpvLayer",
  Irr = "cityReportAreaIrrLayer",
  Roi = "cityReportAreaRoiLayer",
  CostPerMeter = "cityReportAreaCostPerMeterLayer",
  CostPerPassing = "cityReportAreaCostPerPassingLayer",
}

export const opacityMapping = {
  focus: {
    fill: 0.3,
    label: 1,
  },
  unfocus: {
    fill: 0.1,
    label: 0.3,
  },
};
export const unFocusColor = "grey";
export const palette: Record<MultiReportCategory, string[]> = {
  Npv: ["#00C853", "#FF1744"],
  Irr: ["#FFE082", "#FF6F00"],
  Roi: ["#8C9EFF", "#4527A0"],
  CostPerMeter: ["#84FFFF", "#00838F"],
  CostPerPassing: ["#FF80AB", "#880E4F"],
};

export const generateCityReportMapConfiguration = (reports: Report[], multiReportCategory: MultiReportCategory): Configuration => {
  const data = cityReportConfiguration(reports, multiReportCategory);
  return {
    ID: "",
    Key: "",
    SourceID: "",
    Data: {
      ids: pickIDs(data),
      entities: Object.fromEntries(data.map((entity) => [entity.ID, entity])),
    },
    MapChildren: [],
    Type: "MapLayerConfig",
  };
};

/**
 * Create the city report configuration.
 */
export const cityReportConfiguration = (reports: Report[], multiReportCategory: MultiReportCategory): ConfigurationUpsert => {
  const configurations =
    multiReportCategory === "Npv" ? createSubareaConfigByNpv(reports) : createSubareaConfigByAttribute(reports, multiReportCategory);
  const { config: boundaryLayerConfig, descendants: boundaryDescendants } = createSubareaBoundaryConfig(reports);

  // Assemble everything together into one group.
  const layerConfigs = [...configurations.map(({ config }) => config), boundaryLayerConfig];
  const descendantConfigs = [...boundaryDescendants, ...configurations.flatMap(({ descendants }) => descendants)];

  return [...layerConfigs, ...descendantConfigs];
};

const createSubareaBoundaryConfig = (reports: Report[]): FamilialLayerConfig => {
  const boundariesWithReports: string[] = [];
  reports.forEach(({ Status, MultiProjectArea: area }) => {
    if (Status?.State === "COMPLETE" && area) boundariesWithReports.push(area.ID);
  });

  const descendants: LayerStyle[] = [
    labelTemplate(SubareaLayerId.BOUNDARY, undefined, opacityMapping.focus.label),
    {
      ID: `${SubareaLayerId.BOUNDARY}-polygon-stroke-foreground`,
      Name: `${SubareaLayerId.BOUNDARY}-polygon-stroke`,
      GlobalPosition: 1,
      ConfigurationID: SubareaLayerId.BOUNDARY,
      ConfigurationType: "LAYER",
      Position: 0,
      MapboxStyle: {
        type: "line",
        paint: {
          "line-width": 1,
          "line-color": "#555",
        },
      },
      RawStyles: {
        Type: "line",
        LineOpacity: 1,
        LineColor: "black",
        LineWidth: 2,
      },
      Type: "STYLE",
    },
    {
      ID: `${SubareaLayerId.BOUNDARY}-polygon-stroke-background`,
      Name: `${SubareaLayerId.BOUNDARY}-polygon-stroke`,
      GlobalPosition: 1,
      ConfigurationID: SubareaLayerId.BOUNDARY,
      ConfigurationType: "LAYER",
      Position: 0,
      MapboxStyle: {
        type: "line",
        paint: {
          "line-width": ["interpolate", ["linear"], ["zoom"], 8, 0, 10, 4, 14, 6, 16, 9],
          "line-color": "white",
        },
      },
      RawStyles: {},
      Type: "STYLE",
    },
    {
      ID: `${SubareaLayerId.BOUNDARY}-polygon-fill`,
      Name: `${SubareaLayerId.BOUNDARY}-polygon-fill`,
      GlobalPosition: 1,
      ConfigurationID: SubareaLayerId.BOUNDARY,
      ConfigurationType: "LAYER",
      Position: 0,
      MapboxStyle: {
        type: "fill",
        paint: {
          "fill-color": unFocusColor,
          "fill-opacity": ["case", ["!", ["to-boolean", ["feature-state", "hasFocus"]]], opacityMapping.unfocus.fill, 0],
        },
      },
      RawStyles: {},
      Type: "STYLE",
    },
  ];
  const config = layerConfigTemplate(SubareaLayerId.BOUNDARY, "subareas", "Subareas", descendants, []);

  return { config, descendants };
};

/**
 * Creates a layer & styles based on Npv
 */
const createSubareaConfigByNpv = (reports: Report[]): FamilialLayerConfig[] => {
  const descendants = reports
    .filter((report) => report.MultiProjectArea)
    .map((report) => {
      const layerConfigId = `${CityReportLayerId.Npv}-${report.ID}`;
      const { Npv } = report;
      const fillOpacity = Npv ? opacityMapping.focus.fill : opacityMapping.unfocus.fill;
      const strokeColor = Npv ? "black" : unFocusColor;
      let color = unFocusColor;
      if (Npv) {
        color = Npv > 0 ? palette.Npv[0] : palette.Npv[1];
      }

      const fillColor: mapboxgl.Expression = ["case", ["all", ["==", ["get", "Phase"], "NO_PHASE"]], unFocusColor, color];
      const styles = layerStyles(layerConfigId, fillColor, null, fillOpacity, strokeColor);
      const config = layerConfigTemplate(layerConfigId, `output/service_area_${report.ID}`, "Npv", styles, []);

      return { config, descendants: styles } as FamilialLayerConfig;
    })
    .filter((x) => x !== null) as FamilialLayerConfig[];

  return descendants;
};

/**
 * Creates a layer & styles based on an attribute
 */
const createSubareaConfigByAttribute = (reports: Report[], attribute: MultiReportCategory): FamilialLayerConfig[] => {
  const attributeValueList = reports
    .filter((report): report is Report & { [key in typeof attribute]: number } => report[attribute] !== null)
    .map((report) => report[attribute]);
  const colorMap = assignColors(attributeValueList, palette[attribute]);
  const configuration = reports
    .filter((report) => report.MultiProjectArea)
    .map((report) => {
      const layerConfigId = `${CityReportLayerId[attribute]}-${report.ID}`;
      const attributeValue = report[attribute];
      const color = attributeValue ? colorMap?.[attributeValue] : unFocusColor;
      const fillOpacity = attributeValue ? opacityMapping.focus.fill : opacityMapping.unfocus.fill;
      const strokeColor = attributeValue ? "black" : unFocusColor;
      const fillColor: mapboxgl.Expression = ["case", ["all", ["==", ["get", "Phase"], "NO_PHASE"]], unFocusColor, color];
      const styles = layerStyles(layerConfigId, fillColor, null, fillOpacity, strokeColor);
      const config = layerConfigTemplate(layerConfigId, `output/service_area_${report.ID}`, attribute, styles, []);

      return { config, descendants: styles };
    })
    .filter((x) => x !== null) as FamilialLayerConfig[];

  return configuration;
};

/**
 * Assigns colors to a list of numerical values, ensuring that each unique value is assigned a distinct color.
 * If the number of unique values exceeds the predefined colors, a color gradient is generated using `chroma-js`.
 *
 * @example
 * const numbers = [1, 2, 3, 2, 7];
 * const colors = ["#FFE082", "#FFCA28", "#FFB300"];
 * const result = assignColors(numbers, colors);
 * Output:
 * {
 *   1: "#FFE082",
 *   2: "#FFCA28",
 *   3: "#FFB300",
 *   7: "#FF8F00"
 * }
 *
 */
const assignColors = (numbers: number[], colors: string[]): { [number: number]: string } => {
  const uniqueValues = [...new Set(numbers)].sort((a, b) => a - b);
  const colorRange = chroma.scale(colors).mode("lab").colors(uniqueValues.length);

  const valueToColorMap: { [number: number]: string } = {};
  uniqueValues.forEach((value, index) => {
    valueToColorMap[value] = colorRange[index];
  });
  return valueToColorMap;
};

const layerStyles = (
  layerId: string,
  fillColor: mapboxgl.Expression,
  filter?: FilterConfiguration | null,
  opacity?: number,
  strokeColor?: string
): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: `${layerId}-polygon-fill`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "fill-opacity": ["case", ["!", ["boolean", ["feature-state", "hasFocus"], true]], opacityMapping.unfocus.fill, opacity ?? 0.5],
        "fill-color": fillColor,
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: opacity !== undefined ? opacity : 0.5,
      FillColor: "",
    },
    Type: "STYLE",
  },
  {
    ID: `${layerId}-polygon-stroke`,
    Name: `${layerId}-polygon-stroke`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "line",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "line-width": 1,
        "line-opacity": ["case", ["!", ["boolean", ["feature-state", "hasFocus"], true]], opacityMapping.unfocus.fill, opacity ?? 1],
        "line-color": ["case", ["!", ["boolean", ["feature-state", "hasFocus"], true]], unFocusColor, strokeColor ?? "black"],
      },
    },
    RawStyles: {
      Type: "line",
      LineOpacity: 1,
      LineColor: "black",
      LineWidth: 2,
    },
    Type: "STYLE",
  },
];

const labelTemplate = (layerId: string, filter?: FilterConfiguration, opacity?: number): LayerStyle => ({
  ID: `${layerId}-polygon-label`,
  Name: `${layerId}-polygon-label`,
  GlobalPosition: 2,
  ConfigurationID: layerId,
  ConfigurationType: "LAYER",
  Position: 0,
  MapboxStyle: {
    ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
    type: "symbol",
    layout: {
      "text-field": ["get", "name"],
      "text-size": ["interpolate", ["linear"], ["zoom"], 10, 10, 16, 12],
    },
    paint: {
      "text-opacity": ["case", ["!", ["to-boolean", ["feature-state", "hasFocus"]]], opacityMapping.unfocus.label, opacity],
      "text-halo-width": 1.5,
      "text-halo-color": "#ffffff",
    },
  },
  RawStyles: {},
  Type: "STYLE",
});
