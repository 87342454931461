import { useCallback, useRef, useState } from "react";
import { useForm } from "react-final-form";
import { Add as AddIcon } from "@mui/icons-material";
import { Button, Menu, MenuProps } from "@mui/material";

import { ScoreFactor } from "fond/types";

import { DEFAULT_SCORING_INPUT_VALUE, scoringInputConfigs } from "./scoringInputConfiguration";
import { ScoringItemsMenuItem } from "./ScoringItemsMenuItem";

type ScoringItemsMenuProps = Omit<MenuProps, "open" | "anchorEl" | "onClose"> & {
  id: string;
  factor: ScoreFactor;
};

export const ScoringItemsMenu: React.FC<ScoringItemsMenuProps> = ({ id, factor, ...props }: ScoringItemsMenuProps) => {
  const { getFieldState } = useForm(); // use the form state rather than field value because it works more reliably in tests
  const [isOpen, setOpen] = useState(false);
  const anchorEl = useRef<null | HTMLButtonElement>(null);

  const toggleOpen = () => {
    setOpen((currentIsOpen) => !currentIsOpen);
  };

  /**
   * Selecting an item is analogous to wanting a value for this field, so we set it to the default value.
   */
  const onSelect = useCallback(
    (itemId: string) => {
      const fieldState = getFieldState(itemId);
      if (fieldState) {
        if (fieldState.value == null) {
          fieldState.change(DEFAULT_SCORING_INPUT_VALUE);
        } else {
          fieldState.change(null);
        }
      }
    },
    [getFieldState]
  );

  const items = scoringInputConfigs.filter((input) => (factor ? input.factor === factor : true));
  return (
    <>
      <Button
        ref={anchorEl}
        color="primary"
        onClick={toggleOpen}
        fullWidth
        aria-haspopup="true"
        aria-controls="revenue-factors-menu"
        aria-expanded="false"
      >
        <AddIcon fontSize="small" /> Add input
      </Button>
      <Menu
        id={id}
        {...props}
        open={isOpen}
        onClose={toggleOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {items.map((item) => (
          <ScoringItemsMenuItem key={item.id} item={item} onSelect={onSelect} />
        ))}
      </Menu>
    </>
  );
};
