import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { CameraOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectFolderById, useGetFoldersQuery, useGetMultiProjectQuery, useGetMultiReportQuery } from "fond/api";
import { NavigateError } from "fond/navigation/NavigateError";
import { loadMultiProject } from "fond/project/redux";
import { Navigation } from "fond/projects";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { PageContent } from "fond/widgets";

import CityReportFolderPageContent from "./CityReportFolderPageContent";

interface RouteParams {
  multiReportFolderId: string;
}

/**
 * A top level component used for displaying a market lens report folder.
 */
const CityReportFolder: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isFetching: isFoldersFetching } = useGetFoldersQuery(undefined);
  const { multiReportFolderId } = useParams<keyof RouteParams>();

  const currentFolder = useSelector((state: Store) => (multiReportFolderId ? selectFolderById(state, multiReportFolderId) : undefined));
  const folderName = currentFolder?.MultiReport?.Name ?? "";
  const { data: multiReport } = useGetMultiReportQuery(currentFolder?.MultiReport?.ID ?? skipToken);
  const { data: multiProject } = useGetMultiProjectQuery(multiReport?.MultiProject?.ID ?? skipToken);

  useEffect(() => {
    if (multiProject?.ID) {
      dispatch(loadMultiProject({ uuid: multiProject?.ID }));
    }
  }, [dispatch, multiProject?.ID]);

  /**
   * If multiReportFolderId is not null but currentFolder is null, it indicates that the current user has no access to the multi project,
   * redirect to projects landing page.
   */
  if (multiReportFolderId && !currentFolder && !isFoldersFetching) {
    return <NavigateError to="/404" resourceType="MULTI_REPORT" />;
  }

  return (
    <PageContent
      windowTitle={folderName}
      title={
        <Box display="flex" alignItems="center">
          <CameraOutlined color="tertiary" sx={{ mr: 1 }} />
          <Typography variant="h1" data-testid="page-title">
            {folderName}
          </Typography>
        </Box>
      }
      mainContent={multiReport && <CityReportFolderPageContent multiProject={multiProject} multiReport={multiReport} />}
      navigation={<Navigation />}
      testId="multi-report-content"
    />
  );
};

export default CityReportFolder;
