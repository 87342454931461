/**
 * Invalidate a URL by adding a timestamped `dt` parameter to the query string.
 * @param url The URL to modify.
 */
export const addTimestampToUrl = (url: string | URL): string => {
  const parts = url.toString().split("?");
  if (parts.length > 2) {
    throw new Error("URL contains more than one query string");
  }

  const baseUrl = parts[0];
  const searchParams = new URLSearchParams(parts.length === 2 ? parts[1] : "");
  searchParams.set("dt", Date.now().toString());
  return `${baseUrl}?${searchParams.toString()}`;
};
