export enum GridId {
  SEARCH = "SEARCH",
  CITY_PLANNER_FEATURES_TABLE = "CITY_PLANNER_FEATURES_TABLE",
  CITY_PLANNER_SUMMARY = "CITY_PLANNER_SUMMARY",
  CITY_PLANNER_REPORT_SUMMARY = "CITY_PLANNER_REPORT_SUMMARY",
  WORKSPACE = "WORKSPACE",
  RECENT_PROJECTS = "RECENT_PROJECTS",
  SHARED_PROJECTS = "SHARED_PROJECTS",
  STARRED_PROJECTS = "STARRED_PROJECTS",
}
