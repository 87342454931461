import { useFormState } from "react-final-form";
import { Badge } from "@mui/material";
import { get } from "lodash";

import { ScoreFactor } from "fond/types";

import { scoringInputConfigs } from "./scoringInputConfiguration";

type FactorCountBadgeProps = {
  factor: ScoreFactor;
};

export const FactorCountBadge: React.FC<FactorCountBadgeProps> = ({ factor }: FactorCountBadgeProps) => {
  const formValues = useFormState().values;
  // the number of items that are active based on the factor and whether they have a value in the form
  const activeItems = scoringInputConfigs.filter((input) => input.factor === factor && get(formValues, input.id) != null);

  return <Badge data-testid="factor-count-badge" badgeContent={activeItems.length} color="subtle" showZero />;
};
